.ts-patient-chat {
    .chatbox {
        height: 0;
        min-height: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: z-index(modal);

        @include transition(all);

        @include media-breakpoint-up(md) {
            width: $sidebar-width;
            position: fixed;
            right: 1rem;
            bottom: 0;
        }

        &.expanded {
            height: 100%;
            min-height: 100%;
            border: 1px solid $color-gray;

            @include media-breakpoint-up(md) {
                height: 25rem;
                min-height: 25rem;
            }
        }
    }

    .open-chat-button {
        z-index: z-index(overlay);
        padding: 0.7rem;
        width: 3rem;
        height: 3rem;

        @include transition(all);

        .number-indicator {
            position: absolute;
            left: 0;
            bottom: -5px;
        }
    }
}
