$login-header-height: 100px;
$login-card-width: 420px;

.ts-login-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background: url("./res/login_bg.jpg") no-repeat center center fixed,
        linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-blend-mode: overlay;
    background-attachment: fixed;

    a {
        color: $color-black;
        text-decoration: underline;
        font-size: 0.9rem;
    }

    &__header {
        height: $login-header-height;
        display: flex;
        align-items: center;
        padding: 2rem;

        @include media-breakpoint-up(lg) {
            padding: 2rem 5rem;
        }

        .ts-logo {
            img {
                height: 30px;

                @include media-breakpoint-up(lg) {
                    height: 50px;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        padding: 1rem;
        overflow-y: auto;

        @include media-breakpoint-up(lg) {
            padding-top: 8rem;
        }

        .wrapper {
            position: relative;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: $login-card-width;
            }

            .dash-shadow {
                position: absolute;
                left: -4rem;
                top: -4rem;
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                    width: $login-card-width;
                }
            }

            .children-container {
                background: $color-white;
                border-radius: 0.25rem;
                width: 100%;
                padding: 3rem;
                position: relative;

                @include media-breakpoint-up(lg) {
                    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
