.report-form {
    padding: 20px;
    max-width: 800px;
}

.react-datepicker {
    &-wrapper,
    &__input-container,
    &__input-container input {
        display: block;
    }
}

.recharts-wrapper {
    font-family: Roboto;
    fill: #555;

    .recharts-cartesian-axis-tick {
        margin-top: 10px;

        font-size: 12px;

        text {
            fill: #999;
        }
    }

    .recharts-cartesian-axis-ticks {
        padding-top: 20px;
    }
}

.chart {
    display: inline-block;

    .chart-title {
        text-align: center;
    }
}

.utilization {
    width: 500px;

    td.utilization-value {
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        width: 10%;

        &:hover {
            background-color: #999;
        }

        &-good {
            background-color: #2ecc40 !important;
        }

        &-bad {
            background-color: #ffcf00 !important;
        }
    }

    td.level2 {
        padding-left: 30px;
    }

    .note {
        margin-top: -10px;
        margin-bottom: 15px;
        font-size: 14px;
        // TODO: Figure out how that should work
        // color: $color-gray;
        color: #6c7276;
        font-style: italic;
    }

    .table-is-printable-check {
        display: inline-block;
        float: right;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 14px;
    }
}

.branding-groups {
    .group {
        font-size: 16px;
    }
    .group-ids {
        font-size: 14px;
    }
}

.users-report-table {
    display: inline-block;
    overflow-x: auto;

    thead th,
    td {
        text-align: center;
        vertical-align: middle;
    }

    thead th {
        font-size: 14px;
    }

    td {
        white-space: nowrap;
        hyphens: none;
        cursor: pointer;
    }

    .uuid {
        font-size: 10px;
    }
}

.consults-report-totals-table {
    margin-bottom: 30px;
    .consults-report-totals-table-title {
        padding-right: 10px;
        cursor: default;
    }
    .consults-report-totals-table-value {
        cursor: pointer;
    }
}

.consults-report-table {
    width: unset;
}

.debug-consults-table {
    font-size: 14px;
}

img.reportLogo {
    width: 170px;
}

.commonReportLogoWrapper {
    text-align: right;
}

.commonReportTelespineLogoWrapper {
    text-align: right;
    padding-top: 40px;
}

.commonReportData {
    margin-top: 50px;
    counter-reset: page 1;

    .headerCol {
        padding-bottom: 50px;
    }
}

.commonReportDefinitions {
    margin-top: 50px;

    h6 {
        padding-top: 8px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    h5 {
        padding-top: 20px;
        margin-bottom: 5px;
    }
}

.secondPageHeader {
    display: none;
}

@media print {
    body {
        height: auto;
    }

    .coach-layout {
        overflow: visible;

        &__content-wrapper {
            overflow: visible;
        }
    }

    div.pageCounter {
        counter-increment: page;
    }

    .secondPageHeader {
        display: unset;
    }

    .ts-header {
        display: none !important;
    }
    .sidebar {
        display: none !important;
    }

    .report-form {
        display: none;
    }

    .utilization {
        .table-is-printable-check {
            display: none;
        }
    }

    .col,
    .container,
    .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }
    .commonReportData {
        break-after: always;

        .footer {
            position: absolute;
            bottom: 0;
        }
    }

    .commonReportDefinitions {
        page-break-before: always;
        position: relative;
        height: 90vh;

        .footer {
            position: absolute;
            bottom: 0;
        }
    }

    .footer {
        width: 50%;
    }

    //.footer:after {
    //    content: counter(page);
    //}
    .ts-page-footer {
        display: none !important;
    }
    .ts-sidebar-container {
        overflow-x: auto !important;
    }
}

.satisfaction-report-table {
    table-layout: fixed;
    overflow-wrap: break-word;

    thead th {
        font-size: 14px;
        text-align: center;
        vertical-align: middle;
    }

    td {
        text-align: center;
        vertical-align: middle;
    }
    .uuid {
        font-size: 10px;
    }
}

.ts-reports-tabs {
    @media print {
        display: none !important;
    }
}

.ts-report-form-container {
    margin-bottom: 3rem !important;

    @media print {
        margin-bottom: 0 !important;
        display: none !important;
    }
}

.ts-debug-tables-container {
    display: block;

    @media print {
        display: none !important;
    }
}

.ts-report-header-print {
    display: none !important;

    @media print {
        display: flex !important;
    }

    &-padding {
        @media print {
            // margin-bottom: 150px;
        }
    }
}

.apexcharts-datalabels {
    .apexcharts-data-labels {
        text {
            transform: translateY(-1%) !important;
        }
    }
}

.ts-registrations-section {
    flex-direction: column;
    margin: 32px 0;

    @media print {
        margin: 150px 0 0 0;
    }
}

.ts-survey-bg {
    background: url("./res/ic_surveys.svg") no-repeat center 85%;
    background-size: 30%;
    height: 225px;

    @media (min-width: 768px) {
        background: url("./res/ic_surveys.svg") no-repeat center 80%;
        background-size: 70%;
        height: 225px;
    }

    @media (min-width: 1200px) {
        background: url("./res/ic_surveys.svg") no-repeat center 90%;
        background-size: 60%;
        height: 250px;
    }

    @media print {
        background: url("./res/ic_surveys.svg") no-repeat center 85%;
        background-size: 20%;
        height: 215px;
    }
}

.ts-registrations-bg {
    background: url("./res/ic_registrations.svg") no-repeat 60% 100%;
    background-size: 43%;
    height: 215px;

    @media (min-width: 768px) {
        background: url("./res/ic_registrations.svg") no-repeat 75% 85%;
        background-size: 65%;
        width: 300px;
    }

    @media (min-width: 1200px) {
        background: url("./res/ic_registrations.svg") no-repeat 75% 95%;
        background-size: 65%;
        height: 215px;
        width: 300px;
    }

    @media print {
        background: url("./res/ic_registrations.svg") no-repeat 55% 100%;
        background-size: 27%;
        height: 215px;
    }
}

.ts-scheduled-bg {
    background: url("./res/ic_coaching.svg") no-repeat center 100%;
    background-size: 50%;
    height: 275px;

    @media (min-width: 768px) {
        background: url("./res/ic_coaching.svg") no-repeat center 100%;
        background-size: 50%;
        height: 275px;
    }

    @media (min-width: 1200px) {
        background: url("./res/ic_coaching.svg") no-repeat center 100%;
        background-size: 40%;
        height: 275px;
    }

    @media print {
        background: url("./res/ic_coaching.svg") no-repeat center 100%;
        background-size: 27%;
        height: 275px;
    }
}

.ts-consults-bg {
    background: url("./res/ic_conversion.svg") no-repeat 40% 100%;
    background-size: 40%;
    height: 215px;
    width: 100%;

    @media (min-width: 768px) {
        background: url("./res/ic_conversion.svg") no-repeat center 100%;
        background-size: 40%;
        height: 275px;
    }

    @media (min-width: 1200px) {
        background: url("./res/ic_conversion.svg") no-repeat 50% 90%;
        background-size: 40%;
        height: 300px;
    }

    @media (min-width: 1675px) {
        background: url("./res/ic_conversion.svg") no-repeat 50% 100%;
        background-size: 30%;
        height: 300px;
    }

    @media print {
        background: url("./res/ic_conversion.svg") no-repeat 50% 100%;
        background-size: 25%;
        height: 275px;
    }
}

.ts-report-page-break {
    display: none !important;

    @media print {
        display: block !important;
        page-break-before: always;
        page-break-inside: avoid;
    }
}

.ts-completed-activities {
    page-break-before: always;
    page-break-inside: avoid;
}

.ts-clinical-outcomes {
    margin: 32px 0 !important;

    @media print {
        margin: 0 !important;
    }
}

.ts-footer-padding {
    height: 250px;

    @media print {
        height: 1500px;
    }
}

.ts-data-grid-header {
    background-color: #f4f6f8;
    border-color: #c4cdd5 !important;
    border-right: 1px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
}

.ts-data-grid-cell {
    border-bottom: none !important;
}
