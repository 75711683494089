.ts-account-dropdown {
    .dropdown-toggle-button {
        width: 3rem;
        height: 3rem;

        &:hover {
            text-decoration: none;
        }
    }

    .toggle-wrapper {
        width: 3rem;
        height: 3rem;
        border: 1px solid $color-main-dark;
    }
}

.ts-user-container {
    &:before {
        background: none;
    }

    &:hover {
        background: none;
    }

    .ts-user-avatar {
        margin-left: 0.75rem;
    }
}

.ts-user-default-avatar {
    border: 1px solid $color-gray;

    div {
        width: 60px;
        padding: 15px !important;
    }
}

.ts-account-profile-link {
    &:hover {
        color: $color-tertiary;
    }
}

.ts-coach-status-indicator {
    bottom: -15px;
    right: 12px;
    background-color: white;
    border-radius: 20px;
    width: 12px;
    height: 12px;

    i {
        font-size: 0.5rem;
    }
}
.ts-away-status {
    background-color: white;
    color: white;
    text-align: center;
    border: 1px solid $color-gray;
    border-radius: 20px;
}
