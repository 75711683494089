.ts-slider {
    width: 100%;
    background-color: $color-gray-light-alt;
    border-radius: 1rem;

    &__range-wrapper {
        padding: 5rem 2rem 1rem 2rem;

        @include media-breakpoint-up(lg) {
            padding: 5rem 8rem 1rem 8rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 5rem 12rem 1rem 12rem;
        }

        &.compact {
            padding: 3rem 2rem 1rem 2rem;

            @include media-breakpoint-up(lg) {
                padding: 3rem 8rem 1rem 8rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 3rem 12rem 1rem 12rem;
            }
        }

        &__track {
            height: 10px;
            background-color: rgba(blue, 0.5);
            border-radius: 1rem;
        }

        &__thumb {
            width: 50px;
            height: 50px;
            border-radius: 2rem;
            box-shadow: 0 3px 3px rgba($color-gray, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &__label {
                color: $color-white;
                font-weight: bold;
                font-size: 24px;
                display: flex;

                i:nth-child(1) {
                    margin-right: 0.4rem;
                }
            }
        }

        &__labels-wrapper {
            &__lines {
                hr {
                    border-top: 1px solid $color-gray;
                }

                .step-wrapper {
                    display: flex;
                    position: relative;

                    &__line {
                        width: 1px;
                        position: absolute;
                        height: 10px;
                        background-color: $color-gray;
                        text-align: center;
                        z-index: z-index(default);
                    }
                }
            }

            &__text {
                .text-wrapper {
                    display: flex;
                    position: relative;

                    &__step {
                        position: absolute;
                        font-size: 18px;
                        font-weight: bold;
                        width: 1px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &__label-text-component {
        margin-top: 1rem;
        padding: 1rem 1rem 3rem 1rem;

        @include media-breakpoint-up(lg) {
            padding: 1rem 3rem 3rem 3rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 1rem 5rem 3rem 5rem;
        }

        &.compact {
            padding: 1rem 1rem 1rem 1rem;

            @include media-breakpoint-up(lg) {
                padding: 1rem 3rem 1rem 3rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 1rem 5rem 1rem 5rem;
            }
        }
    }
}
