.ts-password-field {
    position: relative;

    .rules-button-wrapper {
        position: absolute;
        top: 15px;
        right: 0;
        z-index: z-index(default);
    }
}
