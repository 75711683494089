// keyframes
@-webkit-keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

// functions
@function z-index($key) {
    @return map-get($z-layers, $key);
}

// mixins
@mixin transition($transition...) {
    // to support cross-browser transitions
    transition: $transition $default-transition-options;
    -moz-transition: $transition $default-transition-options;
    -webkit-transition: $transition $default-transition-options;
    -o-transition: $transition $default-transition-options;
    -ms-transition: $transition $default-transition-options;
}

@mixin pulse-animate() {
    -webkit-animation: pulse 3s infinite ease-in-out;
    -o-animation: pulse 3s infinite ease-in-out;
    -ms-animation: pulse 3s infinite ease-in-out;
    -moz-animation: pulse 3s infinite ease-in-out;
    animation: pulse 3s infinite ease-in-out;
}

// global styles
html,
body,
#root,
.ts-app {
    // prevent left/right scrolling
    overflow-x: hidden;

    // set app height to 100%
    height: 100%;
}

body {
    font-family: "Roboto";
}

.no-scroll {
    height: 100%;
    overflow: hidden;
}

button:disabled {
    opacity: 0.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    line-height: 2.25rem;
}

// base button styles (effects separate)
.btn {
    border-radius: 2rem;
    border: 0;
    padding: 0.75rem 1.5rem;
    white-space: nowrap;

    &:focus {
        outline: auto;
    }

    &.compact {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }

    &.btn-default {
        background-color: $color-gray-light-alt;
        color: $color-black;
    }

    &.disabled {
        background-color: $color-gray-light !important;
        color: white;
        opacity: 1;
        pointer-events: none;
    }

    &.btn-link {
        border-radius: 0;
        background: none !important;
        padding: 0;
        text-align: left;
        user-select: text;

        overflow-wrap: anywhere;
        word-wrap: anywhere;
        word-break: break-all;
    }

    &.btn-link-info {
        border-radius: 0;
        background: none !important;
        padding: 0;
        text-align: left;
        text-decoration: underline;
        user-select: text;

        overflow-wrap: anywhere;
        word-wrap: anywhere;
        word-break: break-all;
    }

    &.btn-link-default {
        background: none !important;
        text-decoration: underline;
    }
}

// button effects (disabled on touch devices)
.no-touchevents {
    .btn {
        &.btn-default {
            &:hover {
                background-color: $color-gray-light;
                color: $color-black;
            }
        }

        &.btn-outline-primary {
            &:hover {
                color: $color-white;
            }
        }

        &.btn-outline-primary-dark {
            &:hover {
                color: $color-white;
            }
        }

        &.btn-outline-secondary {
            &:hover {
                color: $color-white;
            }
        }

        &.btn-primary {
            &:hover {
                box-shadow: none;
            }

            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        &.btn-secondary {
            &:hover {
                box-shadow: none;
            }

            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        &.disabled {
            &:hover {
                background-color: $color-gray-light;
            }

            &:active,
            &:focus {
                background-color: $color-gray-light;
            }
        }

        &.btn-link {
            &:hover {
                color: inherit;
            }

            &:active,
            &:focus {
                color: inherit;
            }
        }
    }
}

.pulse {
    overflow: visible !important;
    position: relative;
    z-index: z-index(default);
}

.pulse:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 5px solid rgba($color-green, 0.8);
    border-radius: 0.25rem;
    transition: opacity 0.3s, transform 0.3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
}

.hover-pointer:hover {
    cursor: pointer;
}

.menu {
    .options {
        position: relative;
        z-index: z-index(menu);
    }

    .overlay {
        z-index: z-index(menu-overlay);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: none;

        &.show {
            display: block;
        }
    }
}

.gray-block {
    height: 100%;
    padding: 1rem;
    background-color: $color-gray-light-alt;
    // border-radius: 1rem;

    @include media-breakpoint-up(lg) {
        padding: 1rem 1.5rem;
    }

    &-top {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding-bottom: 0;
    }

    &-center {
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    &-bottom {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding-top: 0;
    }

    &.compact {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.line-height-lg {
    line-height: 32px !important;

    @media (min-width: 768px) {
        line-height: 42px !important;
    }
}

.text-italic {
    font-style: italic;
}

.text-underline {
    text-decoration: underline;
}

.text-underline-none {
    text-decoration: none !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-medium-bold {
    font-weight: 700 !important;
}

.text-normal {
    font-weight: 500 !important;
}

.text-weight-light {
    font-weight: 300 !important;
}

.text-xs {
    font-size: 12px;
}

.text-sm {
    font-size: 14px;
}

.text-md {
    font-size: 16px;
}

.text-lg {
    font-size: 22px;
    line-height: 28px;
}

.text-xl {
    font-size: 32px;
    line-height: 32px;
}

.text-1x {
    font-size: 40px;
    line-height: 40px;

    @include media-breakpoint-up(md) {
        font-size: 50px;
        line-height: 50px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 60px;
        line-height: 60px;
    }
}

.text-2x {
    font-size: 70px;
    line-height: 70px;

    @include media-breakpoint-up(sm) {
        font-size: 80px;
        line-height: 80px;
    }
}

.text-orange {
    color: $color-orange !important;
}

.text-main {
    color: $color-main !important;
}

.text-main-alt {
    color: $color-main-alt !important;
}

.text-secondary {
    color: $color-secondary !important;
}

.text-tertiary {
    color: $color-tertiary !important;
}

.text-main-dark {
    color: $color-main-dark !important;
}

.text-mfr-main {
    color: $color-mfr-main !important;
}

.text-blue {
    color: $color-orbit-blue !important;
}

.text-green {
    color: $color-green !important;
}

.text-danger {
    color: $color-red-danger !important;
}

.text-red {
    color: $color-red !important;
}

.text-red-hover:hover {
    color: $color-red !important;
}

.text-black {
    color: $color-black !important;
}

.text-yellow {
    color: $color-yellow !important;
}

.text-white {
    color: $color-white !important;
}

.text-gray {
    color: $color-gray !important;
}

.text-gray-alt {
    color: $color-gray-alt !important;
}

.text-gray-light {
    color: $color-gray-light !important;
}

.text-gray-info {
    color: $color-gray-info !important;
}

.text-shadow {
    text-shadow: 0 5px 5px rgba($color-black, 0.5);
}

.bg-main-dark {
    background-color: $color-main-dark !important;
}

.bg-main-dark-alt {
    background-color: $color-main-dark-alt !important;
}

.bg-none {
    background-color: unset !important;
}

.bg-main {
    background-color: $color-main !important;
}

.bg-main-alt {
    background-color: $color-main-alt !important;
}

.bg-secondary {
    background-color: $color-secondary !important;
}

.bg-tertiary {
    background-color: $color-tertiary !important;
}

.bg-red {
    background-color: $color-red !important;
}

.bg-red-light {
    background-color: $color-red-light !important;
}

.bg-orange {
    background-color: $color-orange !important;
}

.bg-green {
    background-color: $color-green !important;
}

.bg-green-alt {
    background-color: $color-green-alt !important;
}

.bg-yellow {
    background-color: $color-yellow !important;
}

.bg-yellow-light {
    background-color: $color-yellow-light !important;
}

.bg-gray-light {
    background-color: $color-gray-light !important;
}

.hover-bg-gray-light:hover {
    background-color: $color-gray-light !important;
}

.bg-gray-light-alt {
    background-color: $color-gray-light-alt !important;
}

.bg-white {
    background-color: $color-white !important;
}

.break-word {
    word-break: break-word !important;
}

.border-radius-none {
    border-radius: 0 !important;
}

.elevated-sm {
    box-shadow: 0 13px 27px -10px rgba(247, 247, 255, 0.25), 0 4px 8px -4px rgba(0, 0, 0, 0.3);
}

.elevated-md {
    box-shadow: 0 13px 27px -7px rgba(200, 200, 200, 0.25), 0 6px 12px -6px rgba(0, 0, 0, 0.3);
}

.elevated-lg {
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
}

.overflow-y-auto {
    overflow-y: auto;
    overflow-x: hidden;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-75 {
    opacity: 0.75;
}

.opacity-100 {
    opacity: 1;
}

.rounded-xl {
    border-radius: 1rem;
}

.rounded-2xl {
    border-radius: 2rem;
}

.rounded-3xl {
    border-radius: 3rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.z-index-default {
    z-index: z-index(default);
}

// make sure snap engage does not cover footer elements
#designstudio-minimize {
    bottom: $patient-homepage-footer-height !important;
    z-index: z-index(modal-overlay) !important; // should be under modal content

    @include media-breakpoint-up(lg) {
        bottom: $patient-homepage-desktop-footer-height !important;
    }
}

.overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
