.ts-subscription-payment-form {
    #payment-element {
        height: 0px;

        &.visible {
            height: auto;
        }
    }

    .stripe-image {
        height: 2rem;
    }

    & .MuiOutlinedInput-root {
        padding-right: 0px;
    }
}

.ts-subscription-prompt {
    .ts-force-payment-image {
        width: 250px;
        text-align: center;
    }

    .ts-provider-patient-prompt {
        height: 100vh;
        background: url("./res/orbit_bubble.png") no-repeat 100% 0%;

        @media (min-width: 990px) {
            background: url("./res/orbit_bubble.png") no-repeat 100% 0%,
                url("./res/session_ipad_pro.png") no-repeat 132% 50%;
        }

        @media (min-width: 1500px) {
            background: url("./res/orbit_bubble.png") no-repeat 100% 0%,
                url("./res/orbit_devices.png") no-repeat 160% 30%;
        }
    }

    .ts-subscription-logo {
        height: 8em;
    }

    .ts-subscription-prompt-image {
        height: 10rem;
    }

    .close-button {
        top: 0;
        right: 0;
        z-index: z-index(default);
    }

    .dash-shadow {
        left: 1rem;
        top: -1rem;
    }

    .testimonial-image {
        height: 13rem;
        object-fit: cover;
    }

    .ts-subscription-container {
        .ts-subscription-payment-form {
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 1rem;
            box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);

            @media (min-width: 990px) {
                background-color: $color-white;
                border-radius: 1rem;
                box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
            }
        }

        .ts-subscription-image {
            top: 4rem;
            right: 0;
            z-index: -100;

            &__bubble {
                top: 4rem;
                right: -4rem;
                z-index: -100;

                img {
                    width: 45rem;
                }
            }
        }
    }
}

.ts-subscription-renew {
    .footer-copy {
        font-size: 0.8rem;
        color: $color-gray;
    }
}

.ts-provider-subscription-prompt {
    font-family: "Montserrat";

    .close-button {
        top: 0;
        right: 0;
        z-index: z-index(default);
    }

    .dash-shadow {
        left: 1rem;
        top: -1rem;
    }

    .testimonial-image {
        height: 13rem;
        object-fit: cover;
    }
}

.ts-tier-column {
    font-family: "Montserrat";

    border: none;

    .card-subtitle {
        min-height: 2rem;

        @media (min-width: 992px) {
            min-height: 3rem;
        }
    }

    .card-body {
        border: solid 1px $color-main;
        border-radius: 15px;
        box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
    }

    .ts-tier-select {
        min-width: 15rem;

        @media (min-width: 992px) {
            width: 100% !important;
        }
    }

    .ts-tier-price {
        font-size: 24px;

        @media (min-width: 768px) {
            font-size: 24px;
        }

        .discounted {
            font-size: 1em;
            position: relative;
        }

        .discounted::after {
            border-top: 0.075em solid rgba(229, 96, 67, 0.8);
            content: "";
            left: -5%;
            margin-top: calc(0.125em / 2 * 2);
            position: absolute;
            right: -5%;
            top: 11%;
        }
    }

    .ts-tier-feature {
        font-weight: 500;
        font-size: 0.75rem;

        @media (min-width: 768px) {
            font-size: 1rem !important;
        }
    }
}

#card-element {
    height: 40px;
    padding: 12px;
}

.ts-renewal-discount {
    font-size: 1em;
    position: relative;
}

.ts-renewal-discount::after {
    border-top: 0.075em solid rgba(99, 115, 129, 0.8);
    content: "";
    left: -5%;
    margin-top: calc(0.125em / 2 * 2);
    position: absolute;
    right: -5%;
    top: 40%;
}

#discount-code {
    padding-right: 0px !important;
}
