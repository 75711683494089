.ts-dropdown-list {
    margin-left: 1rem;
    width: 100%;
    position: relative;

    &__select-wrapper {
        display: flex;
        padding: 0.5rem;

        select {
            flex: 1 1 auto;
            width: 100%;
            text-overflow: ellipsis;
            border: none;
            font-weight: bold;
            display: block;
            font-size: 16px;
            width: 100%;
            box-sizing: border-box;
            margin: 0;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: transparent;
            background-image: url("./res/down_icon.svg"), linear-gradient(to bottom, transparent 0%, transparent 100%);
            background-repeat: no-repeat, repeat;
            background-position: right 0 top 50%, 0 0;
            background-size: 0.8rem auto, 100%;

            &::-ms-expand {
                display: none;
            }

            &:focus {
                outline: none;
            }

            option {
                font-weight: normal;

                &.default {
                    opacity: 0.8;
                }
            }
        }

        i {
            align-self: center;
            display: block;
        }
    }
}
