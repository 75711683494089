.ts-spinner {
    .wpadding {
        text-align: center;
        padding: 100px 0;
    }
}

.ts-spinner-page {
    height: 100%;
    .ts-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}
