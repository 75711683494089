.sidebar-wrapper {
    min-height: 100vh;
    min-width: 23rem;
    width: $sidebar-width;
    padding: 1rem;
    color: $color-white;
    position: absolute;
    left: 100%;

    @include transition(all); // using all here since width and left change between mobile/desktop

    @include media-breakpoint-up(lg) {
        display: block;
        margin-right: 0;
        box-shadow: 0 0 3px black;
        position: relative;
        left: auto;
    }

    &.show-mobile {
        left: 0;
        width: 100vw;

        @include media-breakpoint-up(lg) {
            position: relative;
            width: auto;
        }

        .sidebar-wrapper__toggle-button {
            left: 0;
        }
    }

    &__toggle-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -$sidebar-toggle-button-length; // negative value
        width: $sidebar-toggle-button-length;
        height: $sidebar-toggle-button-length;
        color: $color-white;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    // -webkit-transition: margin .25s ease-out;
    // -moz-transition: margin .25s ease-out;
    // -o-transition: margin .25s ease-out;
    // transition: margin .25s ease-out;

    //.sidebar-heading {
    //    padding: 0.875rem 1.25rem;
    //    font-size: 1.2rem;
    //}
    //
    //.list-group {
    //    width: 15rem;
    //}
}

#page-content-wrapper {
    width: 100%;
}

.navbar {
    color: $color-white;
}

//#wrapper.toggled #sidebar-wrapper {
//    margin-left: 0;
//}

//@media (min-width: 768px) {
//    #sidebar-wrapper {
//        margin-left: 0;
//    }
//
//    #page-content-wrapper {
//        min-width: 0;
//        width: 100%;
//    }
//
//    #wrapper.toggled #sidebar-wrapper {
//        margin-left: -15rem;
//    }
//}
