.ts-home-page {
    height: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    .program {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 20px;
    }

    .exercise {
        font-size: 1.2rem;
        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
            padding-bottom: $patient-homepage-desktop-footer-height;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
        }
        padding-top: 20px;
        padding-bottom: $patient-homepage-footer-height;

        .header {
            padding-bottom: 20px;

            .week {
                font-weight: 500;

                .dropdown-menu {
                    max-height: 178px;
                    overflow: auto;
                    width: 100%;

                    .dropdown-item {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .title {
                font-weight: 300;
            }

            .benefits {
                font-size: 0.8em;
            }
        }
    }

    .col-zero-right-padding {
        padding-right: 0;
    }

    @include media-breakpoint-up(md) {
        .col-zero-left-padding {
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .col-exercise-player {
            padding-left: 0;
            padding-right: 15px;
        }
    }

    .session {
        .list-group-item {
            border: none;
            padding: 0 5px 5px 0;
            width: 100%;
            text-align: right;

            &:last-child {
                padding-bottom: 0;
            }
        }

        .list-group-horizontal {
            .list-group-item {
                padding: 5px 5px 0 0;

                &:last-child {
                    padding: 5px 0 0 0;
                }
            }
        }
    }

    .session,
    .session-carousel {
        .img-wrapper {
            position: relative;
            display: block !important;

            @include media-breakpoint-up(lg) {
                display: inline;
            }

            img {
                max-width: 100%;
                cursor: pointer;

                @include media-breakpoint-up(lg) {
                    max-height: 100px;
                }

                &.watched {
                    opacity: 0.3;
                }
            }
        }
    }

    .watched-icon {
        color: $color-green;
        position: absolute;
        font-size: 1.2em;
        transform: translate(-50%, -50%);
        font-size: 1.5em;
        font-weight: 600;
        top: 50%;
        left: 50%;
    }

    .program-footer {
        height: $patient-homepage-footer-height;
        position: relative;
        width: 100%;
        bottom: 0;
        @include media-breakpoint-up(sm) {
            height: $patient-homepage-desktop-footer-height;
        }
        @include media-breakpoint-up(lg) {
            position: relative;
            padding: 0 1em;
        }

        .btns-col {
            text-align: center;
            @include media-breakpoint-up(sm) {
                text-align: right;
            }
            @include media-breakpoint-up(xl) {
                padding-right: 100px;
            }

            .btn {
                margin: 5px;
            }
        }
    }

    .exercise-reps {
        &.list-group-horizontal {
            .list-group-item {
                border: none;
                margin: 5px;
                padding-top: 10px;
                .title {
                    font-size: 14px;
                    line-height: 1em;
                }
                .value {
                    font-size: 36px;
                    line-height: 1em;
                    padding-left: 10px;
                    margin-bottom: -3px;
                }
            }
        }
        .list-group-item {
            border: 1px solid $color-gray-light;
            padding: 10px 0;
            margin-bottom: 5px;
            min-width: 70px;
            text-align: center;
            div {
                text-align: center;

                img {
                    width: 34px;
                }
                .sets-icon {
                    width: 26px;
                }
                .duration-icon {
                    width: 26px;
                }
            }

            .title {
                font-size: 0.6em;
                font-weight: bold;
                margin: 0 5px;
            }

            .value {
                font-size: 1.2em;
                font-weight: bold;
            }

            .practice {
                .title {
                    font-size: 1em;
                    font-weight: bold;

                    @include media-breakpoint-up(lg) {
                        font-size: 0.5em;
                    }
                }
            }
        }
    }

    .exercise-steps {
        margin-bottom: 15px;
        font-size: 1rem;
        .instructions-title {
            font-size: 1.5rem;
            font-weight: 300;
            cursor: pointer;
            margin-bottom: 15px;
            &:hover {
                text-decoration: underline;
            }
        }

        .step {
            margin: 5px 0 0 0;
            cursor: pointer;
            &:hover {
                //background-color: $gray-200;
                .step-title {
                    text-decoration: underline;
                }
            }
        }

        .current-step {
            color: $color-green;
        }

        .step-title {
            font-weight: bold;
            text-align: center;
        }

        .steps-body.mobile {
            height: 80px;
            overflow: hidden;
            position: relative;

            &.show {
                height: auto;
            }

            .gradient {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                cursor: pointer;
            }
        }
    }

    .progress-wrapper {
        .progress {
            height: 6px;
            width: 100%;

            .progress-bar {
                border-radius: 1rem;
            }
        }
    }

    .cta {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 100%;
        background-color: $color-white;

        .cta-inner {
            padding: 15px;
            max-width: 800px;
            margin: 0 auto;
            position: relative;

            .cta-buttons {
                text-align: center;

                .btn {
                    margin-top: 20px;
                }
            }
            .paused-close-button {
                position: absolute;
                top: 0;
                right: 0.5rem;
                font-size: 1.2rem;
            }
        }
    }

    .upcoming-appointment {
        font-size: 1.2rem;

        .headline {
            font-weight: 300;
            cursor: pointer;

            @include media-breakpoint-up(lg) {
                cursor: auto;
            }
        }

        .cancel-button {
            text-transform: uppercase;
            color: $color-gray;
            &:hover {
                background-color: $color-gray-light;
            }
        }
    }
}

.dropdown-custom-toggle {
    color: $color-black;
    &:hover {
        color: unset;
    }
}
