.ts-help-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 0 20px 20px;
    background-color: $color-mfr-slate-main;
    color: $color-white;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 4px;

    &:hover {
        background-color: $color-mfr-slate-dark;
        color: $color-white;
        text-decoration: none;
    }
}

.branded-logo {
    object-fit: contain;
}
