.ts-user-chat-icon {
    margin-right: 12px;
}

.ts-resend-invite-icon,
.ts-external-link-icon {
    margin-right: 14px;
}

.ts-quick-filter {
    max-height: 56px;

    div {
        height: 100%;

        div {
            padding-left: 14px;
        }
    }
}

.ts-client-search {
    input {
        height: 23px !important;
        padding: 16.5px 14px 16.5px 0;
    }
}

#ts-filter-dropdown {
    &.btn.disabled {
        background: none !important;
    }
}

.ts-client-data-loader {
    height: 400px;
}

.ts-clients-table {
    height: calc(100vh - 29rem);
}
