.ts-button {
    position: relative;

    &.ts-btn-primary,
    &.ts-btn-secondary {
        color: $color-white;
    }

    &.ts-btn-square {
        border-radius: 10px;
    }

    &.ts-btn-outline {
        &:hover {
            background-color: $color-gray-light;
        }
    }

    &.ts-btn-outline-primary {
        &:hover {
            color: $color-white;
        }

        &.disabled {
            color: $color-gray;
            border: 1px solid $color-gray;
        }
    }

    &.ts-btn-outline-primary-dark {
        &:hover {
            color: $color-white !important;
        }

        &.disabled {
            color: $color-gray;
            border: 1px solid $color-gray;
        }
    }

    &.ts-btn-no-decoration {
        text-decoration: none;
    }

    &.icon-button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3rem;
        min-height: 3rem;
        padding: 0;

        &.compact {
            width: 1.5rem;
            min-width: 1.5rem;
            height: 1.5rem;
            min-height: 1.5rem;
            padding: 0.4rem;
        }
    }

    .spinner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ts-radio-button-group {
    .ts-radio-button {
        font-weight: bold;
        text-align: left;
        border-radius: 5rem;
        padding: 0.75rem 1.5rem;
        background-color: transparent;
        outline: none;

        &.compact {
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }

        &.selected {
            color: $color-white;
        }

        &.link {
            border: none;
            outline: none;
            color: $color-main-dark;

            &.selected {
                border-radius: 0;
                background-color: $color-gray-light;
            }
        }

        &.animated {
            @include transition(all);
        }
    }
}

.ts-gender-button-group {
    #gender:hover,
    .selected {
        background-color: $color-main-dark;
        color: $color-white !important;
    }
}
