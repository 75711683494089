.ts-text-input {
    label {
        position: absolute;
        bottom: 0;
        margin-bottom: 0.2rem;

        @include transition(all);

        &.selected {
            bottom: 1.75rem;
            font-size: 0.8rem;

            &.error {
                color: $color-red;
            }
        }
    }

    &__input-wrapper {
        position: relative;
        border-bottom: 1px solid $color-gray-light;
        display: flex;
        margin-top: 1.5rem;

        &.is-invalid {
            border-color: $color-red;
        }

        .input-element {
            border: 0;
            flex: 1 1 auto;
            margin-right: 1rem;
        }

        .icon {
            color: $color-red;
            font-size: 1.5rem;
        }
    }
}
