.ts-user-info {
    max-width: 40rem;
}

.user-info-loader {
    height: 20rem;
}

.ts-save-changes-button {
    padding: 8px 16px !important;
    border: 1px solid $color-tertiary !important;
    border-radius: 8px !important;
    background-color: $color-tertiary !important;
    color: $color-white !important;

    &:disabled {
        background-color: $white !important;
        border: 1px solid $color-gray !important;
        color: $color-gray !important;
    }
}

.ts-add-user {
    height: 36px;
}
