.survey-question-screen,
.survey-centered-container {
    .ts-logo {
        height: 60px;
    }
}

.survey-question-screen {
    padding-top: 1rem;

    .range-defintion-text {
        opacity: 0.7;

        @include media-breakpoint-up(lg) {
            opacity: 1;
        }
    }
}

.survey-centered-container {
    padding-top: 1rem;
}

.ts-satisfaction-question-container {
    .gray-block {
        padding: 2rem 0;

        @include media-breakpoint-up(sm) {
            padding: 2rem 4rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem 6rem;
        }
    }
}

.ts-survey-screen-wrapper {
    transition: opacity 0.4s ease-in; // changes to this timing should align with the timeout in Survey.js
    opacity: 1;

    &.hidden {
        opacity: 0;
    }
}
