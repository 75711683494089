$hero-text-font-size: 52px;

// individual screen styles
.survey-onboarding-introduction {
    // height: 100%;

    @media (min-width: 1200px) {
        position: relative;
    }

    // background-color: $color-main-dark;

    .hero {
        background-color: $color-white;

        @include media-breakpoint-up(xl) {
            padding-top: 8rem;
        }

        &-text {
            font-size: $hero-text-font-size;
            font-weight: bold;
            line-height: 52px;
            color: $color-gray-alt;
            margin-top: 5rem;
            padding: 0 1rem;

            @include media-breakpoint-up(xl) {
                margin-bottom: 6rem; // to prevent text/background overlap
            }

            .ts-gray-logo {
                height: $hero-text-font-size - 4px; // slightly smaller than hero-text font size
            }

            .product-logo {
                height: 65px;
            }

            .partner-attribution-wrapper {
                font-size: 14px;
                color: $color-gray;
                text-align: center;

                &__logo {
                    width: 100px;
                }
            }
        }

        .partner-wrapper {
            display: flex;
            align-items: center;
            margin-top: -($hero-text-font-size - 4px);
            transform: translateY(30px);

            .partner-text {
                text-transform: uppercase;
                font-size: 18px;
                margin-right: 0.5rem;
                white-space: nowrap;
            }
        }

        .card-wrapper {
            display: inline-block;
            position: relative;

            .card {
                max-width: 288px;
                z-index: z-index(default);
                border: none;
                box-shadow: 3px 3px 9px 3px rgba($color-main-dark, 0.1);

                .card-text {
                    color: $color-gray;
                    font-size: 12px;
                }

                .card-subtitle {
                    font-size: 17px;
                    font-weight: 300;
                }
            }

            .dash-shadow {
                position: absolute;
                left: 1rem;
                top: -1.5rem;
            }
        }
    }

    .blue-path {
        width: 100%;

        @include media-breakpoint-up(xl) {
            margin-top: -14rem;
        }

        // custom breakpoints here to support ultra-wide monitors
        @media (min-width: 1500px) {
            margin-top: -19rem;
        }

        @media (min-width: 1900px) {
            margin-top: -25rem;
        }
    }

    .intro-text {
        color: $color-white;
        background-color: $color-main-dark;
        font-size: 20px;
        flex: 1 1 auto;

        // to fix a rounding issue on OSX browsers
        margin-top: -1px;
        position: relative;
    }
}

.survey-actions {
    .action-button {
        font-weight: bold;
        text-align: left;
        border-radius: 0.25rem;
        padding: 0.75rem 1.5rem;
        outline: none;

        @include transition(all);

        &.selected {
            color: $color-white !important;
        }
    }
}

.survey-pain-location-select {
    &__option {
        text-align: center;

        .radio {
            border: 1px solid $color-gray;
            border-radius: 1rem;
            padding: 0.25rem;
            overflow: hidden;

            @include transition(box-shadow);

            .option-image {
                max-width: 5rem;
                width: 100%;
            }
        }
    }
}

.survey-option-list {
    .list-item {
        min-height: 42px;
        letter-spacing: 0.15008px;

        i {
            width: 42px;
            min-width: 42px;
            height: 42px;
        }
    }

    &__radio {
        background-color: transparent;
        font-weight: bold;

        &.selected {
            color: $color-white;
        }
    }
}

.survey-smart-goal {
    &__action-text {
        font-weight: bold;
        white-space: nowrap;
    }

    &__final-text {
        i.fas {
            color: $color-green;
        }
    }
}

.survey-time-based-conclusion,
.survey-time-based-introduction {
    .subtitle {
        margin-bottom: 4rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 8rem;
        }
    }

    .happy-people-image {
        max-width: 300px;
        width: 100%;
    }

    .happy-person-image {
        max-width: 250px;
        width: 100%;
    }
}

.introduction-about-screen {
    &__cards {
        .wrapper {
            height: 100%;
            display: flex;

            .image {
                width: 75px;
                height: 75px;
                min-width: 75px;
                margin-right: 1rem;

                @include media-breakpoint-up(xl) {
                    width: auto;
                    height: 130px;
                    margin-right: 0;
                }
            }

            .text {
                .heading {
                    margin-bottom: 1rem;
                }

                .description {
                    font-size: 13px;
                }
            }
        }
    }
}

.introduction-meet-coach-screen {
    img.coach-image {
        width: 110px;
        height: 110px;
        border-radius: 5rem;
    }

    .coach-bio {
        font-size: 14px;
    }
}

.introduction-call-to-your-coach-screen {
    .cta-inner {
        padding: 15px;
        //max-width: 800px;
        margin: 0 auto;

        .cta-buttons {
            text-align: center;
            margin-top: 30px;

            .btn {
                margin: 10px;
            }
        }
    }
}

.ts-dtc-onboarding {
    position: relative;

    .ts-orbit-icon-gradient {
        background: none;

        @media (min-width: 992px) {
            background: url("../res/Orbit_Icon_Blue_RGB_Gradient.png") no-repeat -150% 0%;
            width: 100%;
            height: 100%;
            background-size: 40rem;
        }

        @media (min-width: 1200px) {
            background: url("../res/Orbit_Icon_Blue_RGB_Gradient.png") no-repeat -40% 15%;
            width: 100%;
            height: 100%;
            background-size: 50rem;
        }

        @media (min-width: 1500px) {
            background: url("../res/Orbit_Icon_Blue_RGB_Gradient.png") no-repeat -200% 15%;
            width: 100%;
            height: 100%;
            background-size: 50rem;
        }
    }

    .ts-hero-branding-logo {
        margin-top: 60px;
        margin-bottom: 100px;
        justify-content: start;

        @media (max-width: 1200px) {
            margin-top: 0px;
            margin-bottom: 0px !important;
            justify-content: center;
        }
    }

    .ts-intro-greeting {
        font-size: 42px;
    }

    &__footer {
        z-index: z-index(menu);
        padding: 1.5rem 2rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__content {
        scroll-behavior: smooth;
        overflow-x: hidden;
        overflow-y: auto;
    }

    img.devices-image {
        width: 100%;
    }

    &__hero {
        // background: url("../res/hero.jpg") no-repeat center,
        // linear-gradient(rgba($color-main-dark, 0.2), rgba($color-main-dark, 1));
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-blend-mode: darken;
        // padding: 2rem 0;

        .ts-hero-section {
            height: 10rem;

            @media (min-width: 992px) {
                height: 20rem;
            }
        }

        .branded-logo {
            max-width: 100%;

            &.ts-logo {
                max-height: 76px;
            }

            &.partner-logo {
                // these positions are specific to teladoc's partner-branded logo only
                max-height: 112px;
                transform: translateY(18px);
            }
        }
    }

    .ts-faqs-title {
        font-size: 42px;
    }

    .mobile-menu-overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: z-index(menu-overlay);
    }

    img.laptop-image {
        right: -15rem;
        top: -5rem;

        @include media-breakpoint-up(xl) {
            right: -5rem;
        }

        @include media-breakpoint-up(xxl) {
            right: 0;
            // width: 50%;
        }
    }
}
