.ts-start-session {
    &:disabled {
        background-color: #fff !important;
    }
}

.ts-user-detail-item {
    max-height: 26px !important;
}

.ts-client-pause {
    &-active {
        border: 1px solid $color-tertiary;
        background-color: $color-white !important;
        color: $color-tertiary !important;

        &:hover {
            background-color: $color-tertiary !important;
            color: $color-white !important;
        }
    }

    &-inactive {
        border: 1px solid $color-red;
        background-color: $color-white !important;
        color: $color-red !important;

        &:hover {
            background-color: $color-red !important;
            color: $color-white !important;
        }
    }
}

.ts-import-popover {
    position: absolute;
    max-width: 800px;
    min-width: 400px;
    z-index: 100;
    top: 0px !important;
    left: 25px;

    .ts-resource-search-helper {
        border-top: 1px solid $color-gray;
    }

    .ts-search-history-item {
        &:hover {
            background-color: $color-gray-light-alt;
        }
    }
}

.ts-edit-list {
    .ts-search-popover {
        position: relative;
        z-index: 100;
        top: 0;
        left: 247px;
        max-width: 555px;
        min-width: 300px;

        .ts-resource-search-helper {
            border-top: 1px solid $color-gray;
        }

        .ts-search-history-item {
            &:hover {
                background-color: $color-gray-light-alt;
            }
        }
    }
}

.ts-mail-client-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    color: $color-tertiary;
    border: 1px solid $color-tertiary;
    border-radius: 10px;

    &:hover {
        background-color: $color-tertiary;
        color: $color-white;
        text-decoration: none;
    }
}
