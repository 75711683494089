.ts-signup {
    $bg-line-position: 1rem;

    min-height: 100vh;

    background: none;

    @media (min-width: 768px) {
        background: url("./res/orbit_bubble.png") no-repeat 100% 0%;
    }

    @media (min-width: 990px) {
        background: url("./res/session_ipad_pro.png") no-repeat 100% 10%,
            url("./res/orbit_bubble.png") no-repeat 100% 0%;
    }

    // background: url("./res/squiggle_line_vertical.svg") $bg-line-position -15rem repeat-y,
    //     linear-gradient(
    //         90deg,
    //         $color-main-dark 0%,
    //         $color-main-dark $bg-line-position,
    //         $color-white $bg-line-position,
    //         $color-white 100%
    //     );

    // @include media-breakpoint-up(sm) {
    //     $bg-line-position-sm: 5rem;

    //     background: url("./res/squiggle_line_vertical.svg") $bg-line-position-sm -15rem repeat-y,
    //         linear-gradient(
    //             90deg,
    //             $color-main-dark 0%,
    //             $color-main-dark $bg-line-position-sm,
    //             $color-white $bg-line-position-sm,
    //             $color-white 100%
    //         );
    // }

    // @include media-breakpoint-up(lg) {
    //     $bg-line-position-lg: 30%;

    //     background: url("./res/squiggle_line_vertical.svg") calc(#{$bg-line-position-lg} + 1%) 10rem repeat-y,
    //         linear-gradient(
    //             90deg,
    //             $color-main-dark 0%,
    //             $color-main-dark $bg-line-position-lg,
    //             $color-white $bg-line-position-lg,
    //             $color-white 100%
    //         );
    // }

    .ts-branded-logo-signup {
        max-height: 150px;
    }

    .ts-signup-image {
        top: 0;
        right: 0;
    }

    &__header {
        padding: 1rem 0;

        @media (min-width: 768px) {
            padding: 2rem 2rem 2rem 0;
        }

        // @include media-breakpoint-up(sm) {
        //     padding-left: 9rem;
        // }

        // @include media-breakpoint-up(lg) {
        //     padding-left: 5rem;
        // }

        &__logo {
            height: 4rem;

            @media (min-width: 768px) {
                height: 8rem;
            }
        }
    }

    form {
        @include media-breakpoint-up(md) {
            max-width: 480px;
        }
    }

    .phone-adornment {
        padding-bottom: 2px;
    }

    .ts-signup-container {
        @include media-breakpoint-up(xl) {
            // justify-content: center;
        }

        .ts-signup-tos {
            font-family: "Montserrat";
        }

        .ts-signup-cta-formrow {
            @media (max-width: 768px) {
                min-height: 100px;
            }
        }

        .ts-signup-form {
            // background-color: rgba(255, 255, 255, 0.6);
            // box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);

            @media (min-width: 768px) {
                background-color: $color-white;
                box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
            }

            .ts-welcome-title {
                font-size: 20px;
                line-height: 20px;
                font-weight: 700;

                @media (min-width: 768px) {
                    font-size: 32px;
                    line-height: 32px;
                }
            }

            .ts-welcome-subtitle,
            .ts-welcome-paragraph {
                font-size: 14px !important;

                @media (min-width: 768px) {
                    font-size: 20px !important;
                }
            }

            .ts-welcome-title,
            .ts-welcome-subtitle,
            .ts-welcome-paragraph,
            .ts-signup-tos,
            .ts-signup-submit {
                font-family: "Montserrat";
                margin-bottom: 16px;
            }

            .testimonial {
                max-width: 550px;
                position: relative;

                .dash-shadow {
                    position: absolute;
                    right: 0;
                }

                .image {
                    width: 100%;
                    padding-right: 2rem;
                    padding-top: 2rem;
                    position: relative;
                }

                .card {
                    margin: -4rem 3rem 0 1rem;
                    padding: 0 1rem;
                    border: none;
                    border-radius: 0 0 4px 4px;
                }
            }
        }
    }
}

.ts-provider-signup {
    margin-top: 140px;

    $bg-line-position: 1rem;

    min-height: 100vh;

    background: none;

    @media (min-width: 768px) {
        background: url("./res/World-Dots.png") no-repeat 100% 0%;
    }

    @media (min-width: 990px) {
        background: url("./res/mfr_health_ipad.webp") no-repeat 100% 10%, url("./res/World-Dots.png") no-repeat 100% 0%;
    }

    // background: url("./res/squiggle_line_vertical.svg") $bg-line-position -15rem repeat-y,
    //     linear-gradient(
    //         90deg,
    //         $color-main-dark 0%,
    //         $color-main-dark $bg-line-position,
    //         $color-white $bg-line-position,
    //         $color-white 100%
    //     );

    // @include media-breakpoint-up(sm) {
    //     $bg-line-position-sm: 5rem;

    //     background: url("./res/squiggle_line_vertical.svg") $bg-line-position-sm -15rem repeat-y,
    //         linear-gradient(
    //             90deg,
    //             $color-main-dark 0%,
    //             $color-main-dark $bg-line-position-sm,
    //             $color-white $bg-line-position-sm,
    //             $color-white 100%
    //         );
    // }

    // @include media-breakpoint-up(lg) {
    //     $bg-line-position-lg: 30%;

    //     background: url("./res/squiggle_line_vertical.svg") calc(#{$bg-line-position-lg} + 1%) 10rem repeat-y,
    //         linear-gradient(
    //             90deg,
    //             $color-main-dark 0%,
    //             $color-main-dark $bg-line-position-lg,
    //             $color-white $bg-line-position-lg,
    //             $color-white 100%
    //         );
    // }

    .ts-branded-logo-signup {
        max-height: 150px;
    }

    .ts-signup-image {
        top: 0;
        right: 0;
    }

    &__header {
        padding: 1rem 0;

        @media (min-width: 768px) {
            padding: 2rem 2rem 2rem 0;
        }

        // @include media-breakpoint-up(sm) {
        //     padding-left: 9rem;
        // }

        // @include media-breakpoint-up(lg) {
        //     padding-left: 5rem;
        // }

        &__logo {
            height: 4rem;

            @media (min-width: 768px) {
                height: 8rem;
            }
        }
    }

    form {
        @include media-breakpoint-up(md) {
            max-width: 480px;
        }
    }

    .phone-adornment {
        padding-bottom: 2px;
    }

    .ts-signup-container {
        @include media-breakpoint-up(xl) {
            // justify-content: center;
        }

        .ts-signup-tos {
            font-family: "Montserrat";
        }

        .ts-signup-cta-formrow {
            @media (max-width: 768px) {
                min-height: 100px;
            }
        }

        .ts-signup-form {
            // background-color: rgba(255, 255, 255, 0.6);
            // box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);

            @media (min-width: 768px) {
                background-color: $color-white;
                box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
            }

            .ts-welcome-title {
                font-size: 20px;
                line-height: 20px;
                font-weight: 700;

                @media (min-width: 768px) {
                    font-size: 48px;
                    line-height: 32px;
                }
            }

            .ts-welcome-subtitle,
            .ts-welcome-paragraph {
                font-size: 14px !important;

                @media (min-width: 768px) {
                    font-size: 20px !important;
                }
            }

            .ts-welcome-title,
            .ts-welcome-subtitle,
            .ts-welcome-paragraph,
            .ts-signup-tos,
            .ts-signup-submit {
                font-family: "Montserrat";
                margin-bottom: 16px;
            }

            .testimonial {
                max-width: 550px;
                position: relative;

                .dash-shadow {
                    position: absolute;
                    right: 0;
                }

                .image {
                    width: 100%;
                    padding-right: 2rem;
                    padding-top: 2rem;
                    position: relative;
                }

                .card {
                    margin: -4rem 3rem 0 1rem;
                    padding: 0 1rem;
                    border: none;
                    border-radius: 0 0 4px 4px;
                }
            }
        }
    }
}
