.ts-active-meetings {
    .dropdown-menu {
        max-height: 500px;
        width: 400px;
        top: 8px !important;
        right: -185px !important;
    }

    .ts-notification-label {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15rem;
    }

    .ts-notification {
        width: 3rem;
        height: 3rem;
        border-radius: 25px;

        &:hover {
            color: $color-orange;
            background-color: rgba($color-orange, 0.1);

            .ts-bell-icon {
                color: $color-orange;
            }
        }

        .ts-bell-icon {
            color: $color-gray;

            &.expanded {
                color: $color-orange;
            }
        }
    }
}
