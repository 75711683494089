$range-input-line-size: 1rem;

.ts-range-input {
    .label-text-wrapper-mobile {
        margin-right: $range-input-line-size * 2;
    }

    .labels-wrapper-mobile {
        .step-label {
            height: 1px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .labels-wrapper-desktop {
        .step-label {
            width: 1px;
            display: flex;
            justify-content: center;

            .label-text {
                min-width: 8rem;

                @include media-breakpoint-up(lg) {
                    min-width: 14rem;
                }
            }
        }
    }

    .steps-wrapper-mobile {
        margin-right: 1rem;
        border-right: 1px solid $color-black;
        border-bottom: 1px solid $color-black;

        &__line {
            position: absolute;
            border-top: 1px solid $color-black;
            width: $range-input-line-size;
            left: -$range-input-line-size;
            height: 1px;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: right;
            white-space: nowrap;

            .line-label {
                margin-right: $range-input-line-size * 2;
            }
        }
    }

    .steps-wrapper-desktop {
        margin: 1rem 0 3rem 0;
        border-top: 1px solid $color-black;

        &__line {
            position: absolute;
            border-left: 1px solid $color-black;
            height: $range-input-line-size;
            width: 1px;
            text-align: center;
            display: flex;
            justify-content: center;
            white-space: nowrap;
        }
    }

    .step-button {
        padding: 0;
        width: 3rem;
        height: 3rem;

        &.selected {
            color: $color-white;
        }
    }
}
