.ts-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z-index(modal);
    display: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    &.show {
        display: flex;
        align-items: center;
    }

    &.top {
        align-items: start;

        @media (min-height: 1200) {
            left: 300px;
        }
    }

    .ts-intro-modal-wrapper {
        min-height: 450px;
    }

    .ts-intro-modal-heading {
        padding: 0 120px 0 120px;

        @media (max-width: 900px) {
            padding: 0 20px;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-black, 0.8);
        z-index: z-index(modal-overlay);
        opacity: 0.8;
    }

    &__wrapper {
        position: relative;
        z-index: z-index(modal);
        transform: translate3d(0, 0, 200px); // safari layering fix
        background: white;
        border-radius: 1rem;
        margin-top: 5rem;
        margin-bottom: 4rem;
        width: 100%;

        @media (min-height: 500px) {
            margin-top: 7rem;
        }

        .content {
            padding: 0.5rem 1rem;

            &.narrow {
                padding: 1rem 3rem;
            }
        }

        &.fullscreen {
            margin: 0;
            border-radius: 0;
            min-height: 100%;
            display: flex;
            flex-direction: column;

            .content {
                padding: 0;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
            }
        }

        &.sm {
            max-width: 325px;
        }

        &.md {
            max-width: 700px;
        }

        &.lg {
            max-width: 55rem;
        }

        &.xl {
            max-width: 75rem;
        }

        &.flex {
            @include media-breakpoint-up(sm) {
                max-width: 325px;
            }

            @include media-breakpoint-up(md) {
                max-width: 450px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 55rem;
            }
        }

        .vertical {
            max-width: 300px;
        }

        .heading-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            // border-bottom: 1px solid $color-gray-light;
            padding: 0 1rem;

            .heading {
                flex: 1 1 auto;
                margin: 0;
            }

            .close-button {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $color-gray-alt;
            }
        }

        .actions {
            text-align: right;
            padding: 1rem;
            border-top: 1px solid $color-gray-light;

            & > * {
                margin-left: 0.5rem;
            }
        }
    }
}

.ts-intro-modal {
    display: flex;
    min-height: 450px;
}
