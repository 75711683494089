.ts-resource-item {
    .resources-article {
        height: 138px;
    }

    .program-resource {
        border-radius: 30px;
    }

    .assign-resource,
    .unassign-resource,
    .program-resource {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        width: 2rem;
        height: 2rem;
    }

    .assign-resource {
        background: rgba($color-black, 0.3) !important;
    }

    .unassign-resource {
        border: 2px solid $color-green;

        &:hover {
            border: 2px solid $color-red;
            background-color: $color-red !important;
        }
    }

    .play-overlay-box {
        .play-overlay {
            position: absolute;
            line-height: 12px;
            text-decoration: none;
        }

        .play-icon {
            background-color: rgba($color-black, 0.7);
            border-radius: 30px;
            width: 4rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            min-height: 5rem;

            @media (min-width: 768px) {
                min-height: 8rem;
            }
        }
    }

    .resource-duration {
        position: absolute;
        line-height: 12px;
        text-shadow: 1px 1px 1px rgba($color-black, 0.7);
        background-color: rgba($color-black, 0.8);
        border-radius: 2px;
    }

    .card-title {
        line-height: 1.2rem;
    }
}
