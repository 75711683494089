$message-tail-size: 10px;

.ts-chat-container {
    .ts-message-timestamp {
        visibility: hidden;
    }
}

.ts-chat-container:hover {
    .ts-message-timestamp {
        visibility: visible;
    }
}

.ts-chat-message {
    position: relative;
    z-index: z-index(default);

    .profile-image {
        width: 1rem;
        height: 1rem;
    }

    &:after {
        content: "";
        position: absolute;
        left: 7px;
        bottom: -5px;
        width: 0;
        height: 0;
        border: $message-tail-size solid transparent;
        border-left: 0;
        border-top: 0;
        margin-top: -$message-tail-size;
        margin-left: -$message-tail-size;
        transform: rotate(-55deg);
        z-index: z-index(below);
    }

    &.my-message:after {
        left: auto;
        right: 7px;
        border: $message-tail-size solid transparent;
        border-right: 0;
        border-top: 0;
        margin-right: -$message-tail-size;
        transform: rotate(55deg);
    }
}

.ts-chat-message-input {
    padding-bottom: 0.48rem;
    padding-top: 0.48rem;
    border-radius: 1.5rem;

    .send-button {
        position: absolute;
        bottom: 5px;
        right: 5px;
        min-width: 38px;
        min-height: 38px;
    }
}

.ts-unread-indicator {
    width: 2.25rem;
}

.ts-patients-list-name {
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ts-online-container {
    min-height: 18px;
    margin-left: 2.25rem;
}

.ts-owner-indicator {
    height: 22px;
    line-height: 1.8;
}

.ts-new-timestamp-indicator {
    height: 22px;
    line-height: 1.8;
}

.ts-unread-message-indicator {
    width: 17px;
    height: 17px;
    font-size: 12px;
    background: $color-red;
    border-radius: 1rem;
    line-height: 1.8;
    text-align: center;

    i {
        font-size: 0.7em;
    }
}

.ts-chat-info {
    width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .ts-unread-message-indicator {
        width: 10px !important;
        height: 10px !important;
        background: $color-red;
        border-radius: 1rem;
    }
}

.ts-chat-bubble {
    bottom: 8rem;
    right: 1.5rem;
}
