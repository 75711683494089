.ts-articles-page {
    &__categories {
        padding: 5rem 1rem;

        .category-wrapper {
            display: flex;
            width: 100%;

            .title {
                flex: 1 1 auto;
            }

            i {
                font-size: 1.5rem;
            }
        }

        .article-wrapper {
            //display: flex;
            //width: 100%;
            margin-bottom: 2rem;

            .image {
                width: 200px;
                min-width: 200px;
                text-align: center;

                img {
                    width: 200px;
                    min-width: 200px;
                }
            }

            .copy {
                padding-left: 1rem;
                min-width: 300px;

                a:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}

.ts-article-page {
    &__article-wrapper {
        justify-content: center;
        display: flex;
        margin-top: 60px;
    }
    &__article {
        max-width: 100%;
        margin: 0 24px;
    }
    h3,
    h4 {
        padding-top: 1.95rem;
        padding-bottom: 0;
    }
    p {
        padding-top: 10px;
        padding-bottom: 0;
    }
    a {
        word-wrap: break-word;
    }
}
