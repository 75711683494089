.ts-link {
    color: $color-black;

    &:hover {
        text-decoration: underline;
    }
}

.v2-link {
    color: $color-black;

    &:hover {
        text-decoration: underline;
    }
}
