$color-main-dark: #3e4c5b; // dark blue/gray
$color-main-dark-alt: #00354a; // lighter dark blue/gray

$color-main: #3f648e; // Primary orbit color
$color-main-alt: #00adef; // Primary orbit color alt

$color-secondary: #f15e45; // Secondary orbit color

$color-tertiary: #ee9a56; // Tertiary orbit color

$color-mfr-main: #212b36; // MFR main color
$color-mfr-slate-main: #41616d; // MFR slate color
$color-mfr-slate-light: #54707b; // MFR slate color light
$color-mfr-slate-dark: #344d57; // MFR slate color light

$color-gray: #5e5f5e; // dark gray
$color-gray-alt: #797e82; // lighter dark gray

$color-gray-light: #cbccc6; // light gray
$color-gray-light-alt: #f5f5f5; // lighter light gray
$color-gray-info: #637381; // gray for info text

// general colors
$color-black: #000000;
$color-white: #ffffff;
$color-green: #90c259;
$color-green-dark: #4ec089;
$color-green-alt: #94c9a9;
$color-orange: #e59c23;
$color-orbit-blue: #406690;
$color-red: #c62c2c;
$color-red-danger: #721c24;
$color-red-light: lighten($color-red, 45%);
$color-yellow: #ffc107;
$color-yellow-light: #fff3cd;

// bootstrap grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px,
);

// bootstrap container widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1200px,
);

// layers
$z-layers: (
    "modal": 1201,
    "modal-overlay": 1200,
    "menu": 101,
    "menu-overlay": 100,
    "overlay": 50,
    "default": 1,
    "below": -1,
);

// sizes
$sidebar-toggle-button-length: 50px;
$sidebar-width: 20rem;
$patient-homepage-footer-height: 120px;
$patient-homepage-desktop-footer-height: 75px;

// timings
$default-transition-options: 0.15s ease-in-out;
