.schedule-meeting {
    .schedule-info {
        width: 600px;
    }

    .selected-check {
        width: 36px;
        height: 36px;
    }

    .coach-filter,
    .date-picker {
        min-width: 310px;
    }

    .ts-appointment-label {
        width: 8rem;
    }

    .date-picker-wrapper {
        min-height: 357px;
    }

    .step-counter {
        font-weight: bold;

        .number-label {
            width: 36px;
            height: 36px;
        }

        &.mobile {
            display: flex;
            align-items: center;

            @media (min-width: 768px) {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }
    }

    .available-coach,
    .coach-accordion {
        .coach-profile-image {
            width: 36px;
            height: 36px;
        }
    }

    .available-time {
        &.selected,
        &:hover {
            color: $color-white;
        }
    }

    .time-options {
        &.mobile {
            display: block;

            @media (min-width: 1500px) {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @media (min-width: 1500px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .confirmation {
        border: none;

        #notes {
            height: 130px;
            resize: none;
        }

        .character-count {
            font-size: 12px;
        }

        .coach-profile-image {
            width: 120px;
            height: 120px;
            float: left;
            margin-right: 15px;
        }
    }
}

.schedule-loader {
    height: 400px;
}

.ts-no-availability {
    height: inherit;
}

.ts-scheduler-container {
    @media (min-width: 1200px) {
        height: 100%;
    }
}
