// variables first so we can apply to bootstrap overrides
@import "variables";

// import bootstrap (place any variable overrides before this import)
@import "~bootstrap/scss/bootstrap.scss";

// react-slick carousel styles
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "~react-quill/dist/quill.snow.css";

// import parent directory styles
@import "base";

// import component styles
@import "../components/account_dropdown/AccountDropdown";
@import "../components/action_card/ActionCard";
@import "../components/articles/Articles";
@import "../components/button/Button";
@import "../components/chat/Chat";
@import "../components/chat/ChatToggle";
@import "../components/coach/active_meetings/ActiveMeetings";
@import "../components/coach/CoachCalendar";
@import "../components/coach/CoachStatus";
@import "../components/coach/client_management/ClientsManagement";
@import "../components/coach/client_details/ClientDetails";
@import "../components/coach/coach_sidebar/CoachSidebar";
@import "../components/coach/patient_data/PatientsData";
@import "../components/coach/patient_details/PatientDetails";
@import "../components/coach/program_editor/ProgramCreator";
@import "../components/coach/program_editor/ProgramEditor";
@import "../components/coach/program_editor/ProgramTemplateDetailsEditor";
@import "../components/coach/program_editor/ProgramResourcesViewerModal";
@import "../components/coach/program_editor/ResourceCard";
@import "../components/coach/program_editor/ResourcesListEditor";
@import "../containers/coach_layout/CoachLayout";
@import "../components/dropdown_list/DropdownList";
@import "../components/dropzone/Dropzone";
@import "../components/footer/Footer";
@import "../components/form_row/FormRow";
@import "../layouts/dashboard/navbar/NavBar";
@import "../components/link/Link";
@import "../components/list_editor/ListContainer";
@import "../components/login/Login";
@import "../components/logo/Logo";
@import "../components/manage_organization/ManageOrganization";
@import "../components/mfr/ClinicProfile";
@import "../components/mfr/ProviderCard";
@import "../components/mfr/ProviderProfile";
@import "../components/mfr/ProviderSearch";
@import "../components/minimal/editor/MailCompose";
@import "../components/minimal/mail/Mail";
@import "../components/modal/Modal";
@import "../components/my_account/UserInfo";
@import "../components/my_account/UserManagement";
@import "../components/notifications/Toast";
@import "../components/password_field/PasswordField";
@import "../components/patient/ClientMyRoutine";
@import "../components/patient/CoachingDropdown";
@import "../components/patient/OrbitRebrandNotification";
@import "../components/patient/PatientChat";
@import "../components/patient/PatientHomepage";
@import "../components/patient/PatientMyRoutine";
@import "../components/patient/RoutinePlayer";
@import "../components/patient/Scheduler";
@import "../components/patient_card/PatientCard";
@import "../containers/patient_layout/PatientLayout";
@import "../components/player/Player";
@import "../components/profile_image/ProfileImage";
@import "../components/range_input/RangeInput";
@import "../components/reports/ReportsApp";
@import "../components/resources/ResourceItem";
@import "../components/resources/ResourcesFilter";
@import "../components/resources/ResourcesList";
@import "../components/resources/ResourcesPage";
@import "../components/resources/ResourcesLibraryFilter";
@import "../components/minimal/hook-form/RHFTextField";
@import "../components/search_bar/SearchBar";
@import "../components/sidebar/Sidebar";
@import "../components/signup/Signup";
@import "../components/slider/Slider";
@import "../components/spinner/Spinner";
@import "../components/subscription/Subscription";
@import "../components/surveys/screens/styles";
@import "../components/surveys/Survey";
@import "../components/testimonial/Testimonial";
@import "../components/text/Text";
@import "../components/text_input/TextInput";
@import "../components/header/Header";
@import "../containers/sidebar_layout/SidebarLayout";
@import "../components/tos_accept/TOSAccept";
@import "../components/video_player/VideoPlayer";
@import "../components/video_session/VideoSession";
@import "../components/user_invite/UserInviteModal";
@import "../components/welcome_modal/WelcomeModal";

// fonts
@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-MediumItalic.eot");
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
        url("./fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-MediumItalic.woff") format("woff"),
        url("./fonts/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Italic.eot");
    src: local("Roboto Italic"), local("Roboto-Italic"),
        url("./fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Italic.woff") format("woff"), url("./fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Bold.eot");
    src: local("Roboto Bold"), local("Roboto-Bold"), url("./fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Bold.woff") format("woff"), url("./fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.eot");
    src: local("Roboto"), local("Roboto-Regular"), url("./fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Regular.woff") format("woff"), url("./fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Medium.eot");
    src: local("Roboto Medium"), local("Roboto-Medium"),
        url("./fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Medium.woff") format("woff"), url("./fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-BoldItalic.eot");
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
        url("./fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-BoldItalic.woff") format("woff"), url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-ThinItalic.eot");
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"),
        url("./fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-ThinItalic.woff") format("woff"), url("./fonts/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Black.eot");
    src: local("Roboto Black"), local("Roboto-Black"),
        url("./fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Black.woff") format("woff"), url("./fonts/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Light.eot");
    src: local("Roboto Light"), local("Roboto-Light"),
        url("./fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Light.woff") format("woff"), url("./fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-LightItalic.eot");
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
        url("./fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-LightItalic.woff") format("woff"), url("./fonts/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-BlackItalic.eot");
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
        url("./fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-BlackItalic.woff") format("woff"), url("./fonts/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Thin.eot");
    src: local("Roboto Thin"), local("Roboto-Thin"), url("./fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Roboto-Thin.woff") format("woff"), url("./fonts/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

/* montserrat-100 - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-100.eot");
    src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"),
        url("./fonts/montserrat-v23-latin-100.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-100.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-100.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

/* montserrat-300 - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-300.eot");
    src: local("Montserrat Light"), local("Montserrat-Light"),
        url("./fonts/montserrat-v23-latin-300.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-300.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-300.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

/* montserrat-regular - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-regular.eot");
    src: local("Montserrat"), local("Montserrat-Regular"),
        url("./fonts/montserrat-v23-latin-regular.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-regular.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/* montserrat-500 - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-500.eot");
    src: local("Montserrat Medium"), local("Montserrat-Medium"),
        url("./fonts/montserrat-v23-latin-500.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-500.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-500.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

/* montserrat-700 - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-700.eot");
    src: local("Montserrat Medium Bold"), local("Montserrat-MediumBold"),
        url("./fonts/montserrat-v23-latin-700.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-700.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-700.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* montserrat-900 - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-900.eot");
    src: local("Montserrat Black"), local("Montserrat-Black"),
        url("./fonts/montserrat-v23-latin-900.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-900.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-900.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

/* montserrat-100italic - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-100italic.eot");
    src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"),
        url("./fonts/montserrat-v23-latin-100italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-100italic.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-100italic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

/* montserrat-300italic - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-300italic.eot");
    src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"),
        url("./fonts/montserrat-v23-latin-300italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-300italic.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-300italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

/* montserrat-italic - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-italic.eot");
    src: local("Montserrat Italic"), local("Montserrat-Italic"),
        url("./fonts/montserrat-v23-latin-italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-italic.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

/* montserrat-500italic - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-500italic.eot");
    src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"),
        url("./fonts/montserrat-v23-latin-500italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-500italic.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-500italic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

/* montserrat-700italic - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-700italic.eot");
    src: local("Montserrat Medium Italic Bold"), local("Montserrat-MediumItalicBold"),
        url("./fonts/montserrat-v23-latin-700italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-700italic.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-700italic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

/* montserrat-900italic - latin */
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/montserrat-v23-latin-900italic.eot");
    src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"),
        url("./fonts/montserrat-v23-latin-900italic.eot?#iefix") format("embedded-opentype"),
        url("./fonts/montserrat-v23-latin-900italic.woff") format("woff"),
        url("./fonts/montserrat-v23-latin-900italic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

// keyframes
@keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(1.25);
    }
    100% {
        opacity: 0;
        transform: scale(1.25);
    }
}
