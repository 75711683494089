.ts-patients-data {
    .table-container {
        height: 32vh;

        &.full-height {
            height: 100%;
        }
    }

    .ts-invite-limit-prompt {
        width: 10rem;
    }

    .ts-pending-icon {
        font-size: 0.4rem;
    }

    .ts-patients-filters {
        z-index: 100;
        background: linear-gradient(75deg, rgba(22, 28, 36, 0.6), rgba(22, 28, 36, 0.9) 100%);
        opacity: 1;
        transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        top: 0;
        left: 0;

        @media (min-width: 1200px) {
            left: 85px;
        }

        &-open {
            z-index: 100;
            background: linear-gradient(75deg, rgba(22, 28, 36, 0.6), rgba(22, 28, 36, 0.9) 100%);
            opacity: 1;
            transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            top: 0;
            left: 0;

            @media (min-width: 1200px) {
                left: 280px;
            }

            .ts-filters-form {
                background-color: white;
                max-width: 20rem;
            }

            .ts-clear-filters {
                border: 1px solid $color-gray-light;
            }
        }

        .ts-filters-form {
            background-color: white;
            max-width: 20rem;
        }

        .ts-clear-filters {
            border: 1px solid $color-gray-light;
        }
    }

    .ts-filters-count {
        border-radius: 5rem;
        color: $color-white;
    }

    .ts-filter-button {
        color: $color-gray;
        background: none;

        &:hover {
            background-color: $color-gray-light-alt !important;
        }
    }

    .ts-patient-search-icon {
        min-width: 15px;
        min-height: 15px;
        color: $color-gray-alt;
    }

    .ts-patient-search {
        max-width: 23rem;
        border-radius: 30px;
        border: 1px solid $color-gray-light;
    }

    #ts-patient-search {
        border: none;
        height: 2rem;
        padding: 0 1rem;
    }

    .ts-invite-plus-icon {
        margin-right: 8px;
        margin-left: -4px;
    }

    .loader,
    .no-results {
        margin-top: 300px;
    }

    .empty-state {
        margin-top: 70px;
    }
}
