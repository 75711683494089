.slick-dots {
    margin-left: 0;
}

.slick-thumb {
    bottom: -60px;
}

.slick-thumb li {
    width: 60px;
    height: 45px;
}

.slick-thumb li img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.slick-thumb li.slick-active img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.ts-office-details {
    position: absolute;
    width: 300px;
    padding: 16px;
    bottom: 350px;
    left: 85px;
}

.gm-style-iw {
    min-height: 200px;
}

.ts-share-link {
    color: black !important;

    &:hover {
        color: black !important;
    }
}

.ts-contact-button {
    font-size: 0.875rem;
    // color: $color-gray-info !important;
    // text-decoration: underline;
}

.ts-edit-profile-images-overlay {
    position: absolute;
    // left: 118px;
    width: 256px;
    height: 256px;
    border-radius: 140px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.ts-edit-profle-images-avatar {
    position: absolute;
    // left: 118px;
    width: 100%;
    height: 100%;
    // border-radius: 140px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.ts-edit-profile-images-avatar {
    position: absolute;
    // left: 118px;
    width: 256px;
    height: 256px;
    border-radius: 140px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.ts-edit-profile-images-single {
    position: absolute;
    // left: 118px;
    width: 100%;
    height: 100%;
    // border-radius: 140px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.ts-edit-profile-images-multiple {
    position: absolute;
    // left: 118px;
    top: 0;
    width: 100%;
    height: 100%;
    // border-radius: 140px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.ts-edit-profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 100%;
    left: 90%;
    padding: 1rem;
    width: 24px;
    height: 24px;
}

.ts-edit-image-thumbnail {
    height: 72px;
    width: 72px;
}

.ts-edit-image-placeholder {
    height: 72px;
    width: 72px;
    border: dashed 2px $color-gray-light;
    background-color: $color-gray-light-alt;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ts-edit-image-container {
    .ts-edit-image-remove {
        display: block;
        position: relative;
        bottom: 70px;
        left: 23px;
    }
}

.ts-external-link {
    color: $color-gray-info;
}

.ts-clarifying-blurb {
    &-hidden {
        background: linear-gradient(to bottom, transparent, rgba(35, 35, 35, 0.05));
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        height: 85px;
    }
}

.ts-clarifying-blurb-read-more {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 12px;
    top: -15px;
    width: 100%;
    color: $color-white !important;

    &:hover {
        color: $color-white !important;
    }

    span {
        color: $color-white !important;
        background: $color-main-alt;
        border-radius: 20px;

        &:hover {
            background: #009bd7;
            color: $color-white !important;
        }
    }
}
