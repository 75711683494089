.playerWrapper {
    position: relative;
    display: block;
    //max-width: 700px;

    // Center horizontally
    left: 50%;
    transform: translateX(-50%);

    .brightcove-react-player-loader {
        padding-top: 56.25%;

        .video-js {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
