.ts-profile-image {
    &.size-sm {
        width: 1rem;
        height: 1rem;
    }

    &.size-md {
        width: 1.5rem;
        height: 1.5rem;
    }

    &.size-lg {
        width: 2rem;
        height: 2rem;
    }
}
