.quill-root {
    .quill {
        .ql-container {
            &:focus {
                outline: none;
            }

            .ql-editor {
                padding: 16px 24px;

                &::before {
                    padding: 0 8px;
                }
            }

            .ql-editor:focus-visible,
            .ql-editor:focus {
                outline: none;
            }
        }
    }
}
