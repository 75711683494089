.ts-page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 1200;

    .logo {
        height: 25px;
    }
}

.ts-page-footer-fixed {
    .logo {
        height: 25px;
    }
}
