.ts-patients-list-container {
    height: 90%;

    .ts-patients-lists {
        top: 0 !important;
    }
}

.ts-new-patient-icon {
    border-radius: 20px;
    font-size: 9px;
    height: 15px;
    width: 15px;
}
