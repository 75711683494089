.ts-patient-programs {
    .ts-patient-program-spinner {
        min-height: 25rem;
    }

    .ts-program-sessions {
        &.editing {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .container-ghost {
            background-color: $color-white;
            width: 100%;
            padding-left: 2rem;
        }

        .container-ghost-drop {
            transition: transform 0.18s ease-in-out;
            transform: rotateZ(0deg);
        }

        .drop-preview {
            background-color: rgba(150, 150, 200, 0.1);
            border: 1px dashed #abc;
            border-radius: 12px;
            margin: 5px;
        }
    }

    .ts-program-editor {
        .program-title-field,
        .description-field {
            border-radius: 30px;
            min-width: 30rem;

            label {
                top: -0.75rem;
                left: -0.8rem;
                padding-left: 1.25rem;
            }

            input {
                padding: 0.5rem 1rem !important;
            }
        }
    }
}
