.coaching-dropdown {
    .dropdown-item {
        cursor: pointer;
    }

    .headset-icon {
        width: 24px;
    }

    .icon-wrapper {
        width: 3rem;
        height: 3rem;
        display: inline-block;

        &.chat-now {
            background-color: #94c9a9;
        }

        &.on-demand {
            background-color: #fcbf49;
        }

        &.schedule-now {
            background-color: #777da7;
        }

        &.unread-messages {
            background-color: #d5573b;
        }

        i {
            color: $color-white;
        }
    }

    .number-indicator {
        position: absolute;
        right: 7px;
        bottom: -5px;
    }
}
