.ts-provider-search,
.ts-clinic-search {
    input {
        height: 23px !important;
        padding: 16.5px 14px 16.5px 0;
    }
}

.ts-city-suggestions {
    max-height: 150px;
    overflow: scroll;
}

.PhoneInput {
    width: 100%;
    padding-left: 8px;

    input {
        border-style: solid;
        border-radius: 8px;
        border-width: 1px;
        border-color: #d8dbe0 !important;
        padding: 5.5px 14px;
    }

    input:focus {
        outline: none;
        border-color: $color-tertiary !important;
    }
}
