$mobile-menu-button-length: 50px;

.ts-video-session {
    &.mobile-menu-open {
        height: 100vh;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            overflow: auto;
        }
    }

    // temporary header styles while we migrate to v3, remove this later
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;

        &.lighter {
            font-weight: lighter;
        }
    }

    &__content {
        .permission-component {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include media-breakpoint-up(md) {
                height: 60vh;
            }

            .device-info {
                display: flex;
                justify-content: center;

                &__icon {
                    border: 1px solid $color-gray;
                    padding: 1rem;
                    border-radius: 5rem;
                    width: 75px;
                    height: 75px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.audio {
                        font-size: 36px;
                    }

                    &.video {
                        font-size: 30px;
                    }
                }
            }
        }

        .video-component {
            .ot-session-wrapper {
                &__content {
                    position: relative;
                    height: 450px;

                    @include media-breakpoint-up(md) {
                        height: 550px;
                    }

                    &.fullscreen {
                        position: fixed;
                        left: 0;
                        top: 0;
                        right: 0;
                        height: 100%;
                        z-index: z-index(menu);
                    }

                    .connected-wrapper {
                        height: 100%;

                        .fullscreen-button {
                            position: absolute;
                            right: 1rem;
                            top: 1rem;
                            z-index: z-index(default);
                            width: 48px;
                            height: 48px;
                            padding: 0;
                        }

                        .controls {
                            position: absolute;
                            z-index: z-index(overlay);
                            width: 100%;
                            height: 100%;
                            top: 0;

                            .button-wrapper {
                                position: absolute;
                                bottom: 1rem;
                                text-align: center;
                                width: 100%;

                                & > * {
                                    // separate each first child with some space
                                    margin-left: 1rem;
                                }
                            }
                        }

                        .subscribers {
                            height: 100%;

                            & > div {
                                // this targets the OTSubscriber component's main container
                                height: 100%;
                            }
                        }
                    }

                    .publisher {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 150px;
                        height: 100px;

                        & > div {
                            // this targets the OTPublisher component's main container
                            height: 100%;
                        }
                    }

                    .await-coach-message {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: z-index(overlay);
                        justify-content: center;
                        align-items: center;
                        color: white;
                        text-align: center;
                        background: url("/v3/patient_bg.jpg") no-repeat center center;
                        background-size: cover;
                        padding: 3rem;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }

                        & > * {
                            // bring all first children to front
                            z-index: z-index(default);
                        }
                    }

                    .recording-status {
                        position: absolute;
                        bottom: 0.25rem;
                        right: 0.25rem;
                        line-height: 1;

                        @include pulse-animate();

                        i {
                            color: $color-red;
                        }
                    }
                }
            }
        }

        .about-coach {
            .ts-coach-info {
                display: block;

                @media (min-width: 1200px) {
                    display: flex;
                }
            }

            .coach-image {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;

                @media (min-width: 1200px) {
                    display: block;
                    margin-bottom: 0;
                }

                img {
                    max-width: 150px !important;
                    height: 150px !important;
                    border-radius: 100%;
                }
            }
        }
    }
}

.on-demand-waiting-room {
    max-width: 50rem;

    img.finding-coach {
        max-width: 435px;
    }
}

.ts-header.fullscreen {
    display: none;
}

.ts-main-style-container.fullscreen {
    padding-top: 0px;
}
