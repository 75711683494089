.ts-resource-filters {
    max-height: 25rem;
}

.ts-resources-filter-list {
    max-height: 600px;
    overflow: scroll;
}

.ts-quick-filter {
    label {
        &.Mui-focused {
            color: $color-main-alt;
        }
    }

    li {
        padding: 10px;
    }
}
