.ts-resource-card {
    min-width: 200px;

    &.highlight {
        border: 1px solid $color-tertiary;
    }

    &.dimmed {
        opacity: 0.65;
        background-color: rgba($color-gray-light, 0.2);
    }

    .ts-resource-card-preview {
        border-radius: 12px;
        pointer-events: none !important;
        width: 180px;
        height: 120px;
    }

    .play-overlay-box {
        @media (max-width: 900px) {
            .ts-play-overlay {
                background-color: rgba($color-black, 0.3);
                border: 3px solid $color-tertiary;
                border-radius: 40px;
                width: 36px;
                height: 36px;

                .ts-play-icon {
                    background-color: $color-tertiary;
                }

                img {
                    width: 180px;
                    height: 120px;

                    @media (min-width: 768px) {
                        min-height: 8rem;
                    }
                }
            }
        }

        @media (min-width: 900px) {
            .ts-play-overlay {
                .ts-play-icon {
                    display: none;
                }
            }

            &:hover {
                .ts-play-overlay {
                    background-color: rgba($color-black, 0.3);
                    border: 3px solid $color-tertiary;
                    border-radius: 40px;
                    width: 36px;
                    height: 36px;

                    .ts-play-icon {
                        display: block;
                        background-color: $color-tertiary;
                    }

                    img {
                        width: 180px;
                        height: 120px;

                        @media (min-width: 768px) {
                            min-height: 8rem;
                        }
                    }
                }
            }
        }
    }

    .ts-add-resource {
        background-color: $color-tertiary !important;
        color: $color-white !important;
        border-radius: 12px;
    }

    .ts-remove-resource {
        background-color: $color-white !important;
        color: $color-gray !important;
        border-radius: 12px;
        padding: 4px !important;

        &:hover {
            background-color: $color-red !important;
            color: $color-white !important;
        }
    }

    .ts-edit-resource {
        background-color: $color-white !important;
        color: $color-gray !important;
        border-radius: 12px;
        width: 24px;
        height: 24px;

        &:hover {
            background-color: $color-white !important;
            color: $color-main-alt !important;
        }
    }

    .ts-resource-library-container {
        margin-bottom: 40px;
        overflow: auto;
    }

    .ts-program-completed-item {
        top: 43px;
        right: 13px;
        background: $color-green;
        border-radius: 4rem;
    }
}
