.list-container {
    &-editing {
        overflow: hidden;
        max-width: 1100px;
    }

    &.empty {
        height: 100%;
        width: 100%;

        .card {
            background-color: rgba($color-gray-light, 0.7);
            border: 2px dashed black;
            border-radius: 2rem;
            margin-left: 5rem;
        }

        .card:hover {
            background-color: $color-gray-light;
        }
    }

    .resources-counter {
        min-width: 6rem;
    }

    .resource-container {
        height: 8rem;
        width: 8rem;

        .resource-ghost {
            background-color: $color-gray-light-alt;
        }

        .drop-preview {
            background-color: rgba(150, 150, 200, 0.1);
            border: 1px dashed #abc;
            margin: 5px;
        }

        .card {
            .card-body {
                .card-title {
                    height: 1.5rem;
                    width: 7rem;
                    text-overflow: ellipsis;
                    white-space: pre;
                    line-height: 1.5rem;
                }

                .card-header {
                    border: none;
                }
            }

            img {
                width: 100%;
                pointer-events: none !important;
            }
        }

        .empty-card {
            height: 24rem !important;
            background-color: red;
        }
    }
}

.routine-container {
    &-editing {
        overflow: hidden;
        background-color: white;
    }

    &.empty {
        height: 100%;
        width: 100%;

        .card {
            background-color: rgba($color-gray-light, 0.7);
            border: 2px dashed black;
            border-radius: 2rem;
            margin-left: 5rem;
        }

        .card:hover {
            background-color: $color-gray-light;
        }
    }

    .resources-counter {
        min-width: 6rem;
    }

    .resource-container {
        height: 8rem;
        width: 8rem;

        .resource-ghost {
            background-color: $color-gray-light-alt;
        }

        .drop-preview {
            background-color: rgba(150, 150, 200, 0.1);
            border: 1px dashed #abc;
            margin: 5px;
        }

        .card {
            .card-body {
                .card-title {
                    height: 1.5rem;
                    width: 7rem;
                    text-overflow: ellipsis;
                    white-space: pre;
                    line-height: 1.5rem;
                }

                .card-header {
                    border: none;
                }
            }

            img {
                width: 100%;
                pointer-events: none !important;
            }
        }

        .empty-card {
            height: 24rem !important;
            background-color: red;
        }
    }
}
