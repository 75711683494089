.ts-sidebar-layout {
    .ts-sidebar-container {
        overflow-x: scroll;
    }

    .ts-main-style-container {
        height: 100%;

        @media print {
            padding-top: 0px;
        }

        .ts-main-style {
            height: 100%;
        }

        .sidebar {
            width: 0;
            min-width: 0;

            @include transition(all);

            &.expanded {
                width: $sidebar-width;
                min-width: $sidebar-width;
            }

            .header {
                height: $layout-2-header-height;
                min-height: $layout-2-header-height;
            }

            .sidebar-contents {
                width: $sidebar-width;
                position: fixed;
                padding-top: 90px;
                margin-bottom: 40px;
                top: 0;

                .online-indicator {
                    i {
                        font-size: 0.5rem;
                    }
                }
            }
        }
    }
}

.ts-sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 16px;
    margin-bottom: 8px;
}
