.coach-layout {
    height: 100%;

    &__content {
        display: flex;
        flex-direction: column;

        &__children {
            flex: 1 1 auto;
            height: 100%;
        }
    }

    .sidebar-header {
        &__back-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $sidebar-toggle-button-length;
            height: $sidebar-toggle-button-length;
            margin-right: 1rem;
            margin-left: -1rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        &__logo {
            .ts-logo {
                width: 110px;
            }

            .partner-logo {
                width: 135px;
                margin-left: 1.5rem;
                margin-top: 0;
            }
        }

        &__menu-button {
            width: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -1rem;
        }
    }
}
