.PatientCard {
    border-radius: 7px;
    padding: 10px;

    .data {
        width: 100%;
        font-size: 0.75rem;

        .key {
            font-weight: 200;
        }

        table.pf {
            width: 50%;
        }
    }
}
