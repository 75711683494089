.ts-provider-card-image {
    background-image: url("./res/profile-card-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.ts-provider-card-bio {
    display: -webkit-box !important;
    overflow: hidden !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;

    @media (max-width: 768px) {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &-long {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
    }
}
