.ts-resources-page {
    .exercise {
        @include media-breakpoint-up(lg) {
            margin: 20px 0;
        }
    }

    .resource-list {
        @include media-breakpoint-down(md) {
            margin: 0 5px;
        }

        .resource-list-exercise {
            cursor: pointer;
            padding: 10px;

            img {
                width: 100%;
            }

            div {
                text-align: center;
                font-family: Roboto, sans-serif;
                padding-top: 5px;
                font-weight: 500;
            }
        }
    }

    .header {
        font-family: Roboto-Light, sans-serif;
        font-size: 2em;
    }
}
