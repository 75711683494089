.patient-layout {
    height: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        &__children {
            flex: 1 1 auto;
            overflow-y: auto;
            height: 100%;

            @media (min-width: 1200px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__bottom-bar {
        padding: 1.5rem 2rem;
        z-index: 10;

        &__progress {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .progress {
                height: 6px;
                width: 200px;
                margin-bottom: 0.5rem;

                .progress-bar {
                    border-radius: 1rem;
                }
            }
        }

        &__actions {
            & > * {
                // separates each first child with a bit of space
                margin-left: 1rem;
            }
        }
    }

    .next-button i {
        margin-left: 1rem;
    }

    .previous-button i {
        margin-right: 1rem;
    }

    .sidebar-header {
        &__back-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $sidebar-toggle-button-length;
            height: $sidebar-toggle-button-length;
            margin-right: 1rem;
            margin-left: -1rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        &__logo {
            .ts-logo {
                max-width: 110px;
                max-height: 60px;
            }

            .partner-logo {
                width: 135px;
                margin-left: 1.5rem;
                margin-top: 0;
            }
        }

        &__menu-button {
            width: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -1rem;
        }
    }

    .sidebar-content {
        .contact-dropdown {
            .toggle-button {
                i {
                    margin-left: 1rem;
                }
            }
        }
    }
}

$layout-2-header-height: 5rem;
$layout-2-footer-height: 55px;

.ts-patient-layout-2 {
    &__header {
        height: $layout-2-header-height;
        color: $color-white;
        display: flex;
        align-items: center;
        padding: 2rem;
        z-index: z-index(menu-overlay);

        @include media-breakpoint-up(lg) {
            position: fixed;
            top: 0;
            width: 100%;
        }

        @include media-breakpoint-up(xl) {
            padding: 2rem 5rem;
        }

        .logo img {
            height: 30px;

            @include media-breakpoint-up(lg) {
                height: 40px;
            }
        }

        .navigation {
            flex: 1 1 auto;
            text-align: center;

            a {
                color: $color-white;
                margin: 1.5rem;

                &:hover {
                    text-decoration: none;
                }

                &.selected {
                    border-bottom: 2px solid $color-white;
                    padding-bottom: 0.25rem;
                }
            }
        }

        .user-menu {
            .dropdown button {
                color: $color-white;
            }
        }

        .mobile-controls {
            .mobile-menu {
                margin-left: 1rem;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .mobile-menu-overlay {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: z-index(modal-overlay);
            display: none;
            // padding: 2rem;

            &.show {
                display: flex;
                flex-direction: column;
            }

            &__header {
                height: $layout-2-header-height;
                display: flex;
                align-items: center;
                padding: 2rem;
                margin-bottom: 3rem;

                .close-button {
                    position: absolute;
                    right: 2rem;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $color-white;
                }
            }

            &__navigation {
                flex: 1 1 auto;
                .item {
                    padding: 0 2rem;

                    a {
                        color: $color-white;

                        &.selected {
                            border-bottom: 2px solid $color-white;
                            padding-bottom: 0.25rem;
                        }
                    }
                }
            }
        }
    }

    &__scrollable {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;

        @include media-breakpoint-up(lg) {
            padding-top: $layout-2-header-height;
        }

        &.no-header {
            padding-top: 0;
        }

        .page-title {
            background-color: $color-gray-light-alt;
            min-height: 97px;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid $color-gray-light;
            display: flex;
            align-items: center;

            font-size: 1.5em;
            @include media-breakpoint-up(md) {
                font-size: 2em;
            }

            .wrapper {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 1rem;

                .heading {
                    flex: 1 1 auto;
                    margin: 0;

                    .title1 {
                        letter-spacing: -0.49px;
                        font-weight: bold;
                        padding-right: 10px;
                    }
                    .title2 {
                        font-weight: 300;
                        border-left: 1px solid #bbb;
                        padding-left: 10px;
                    }
                }

                .close-button {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .page-footer {
        height: $layout-2-footer-height;
        padding: 1rem;
        color: $color-white;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(xl) {
            padding: 1rem 5rem;
        }

        .logo img {
            height: 25px;
        }

        .copyright {
            flex: 1 1 auto;
            font-size: 0.8rem;
            text-align: right;
        }
    }
}
