$fixed-width: 5%;
$fixed-width-small: 1%;

.month-view {
    .day {
        min-height: 100px;
    }
}

.coach-info {
    min-width: 100px !important;
}

.coach-table {
    .cell {
        border-right: 1px solid $color-gray-light;
        border-bottom: 1px solid $color-gray-light;
        height: 20px;
        width: 1%;
        max-width: 50px;

        &-bottom {
            border-right: none;
            border-left: none;
            border-bottom: 1px solid $color-gray-light;
            height: 40px;
        }

        &-small {
            border-right: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            width: $fixed-width-small;
        }

        &-no-bottom {
            border-bottom: none !important;
        }

        &-no-bottom-small {
            border-bottom: none !important;
        }
    }
}

.time-label {
    border-bottom: none;
    position: relative;
    top: 12px;
    height: 20px;
    width: 5%;

    @include media-breakpoint-down(sm) {
        font-size: 11px !important;
    }

    &-bottom {
        border-bottom: 1px solid $color-gray-light;
    }

    &-zone {
        top: 0;
        border-bottom: 1px solid $color-gray-light !important;
        width: $fixed-width;
    }
}

.day-view {
    position: absolute;

    .layout {
        position: relative;
    }

    .fixed-width {
        width: 5%;

        &-small {
            width: 1%;
        }
    }

    .schedule {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        .times-column {
            width: 6%;
            border: none;
        }

        .column {
            height: 100% !important;
            border: none;

            .event {
                position: absolute;
                margin: 0 2.5% 0 0;
                width: 100%;
                top: 0;
                left: 0;

                .block {
                    position: absolute;
                    border-radius: 2px;
                }

                .open-slot {
                    &-time {
                        display: none;
                    }

                    .range {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:hover {
                        .open-slot-time {
                            display: flex;
                            background-color: $color-green;
                            color: $color-white;
                        }
                    }
                }

                .title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre;
                }
            }
        }
    }

    .hour {
        border-right: 1px solid $color-gray-light;
        border-bottom: 1px solid $color-gray-light;
        height: 20px;

        &-bottom {
            border-bottom: 1px dashed $color-gray-light;
            border-right: 1px solid $color-gray-light;
        }

        &-small {
            border-right: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            width: $fixed-width-small;
        }
    }

    .time-label {
        border-bottom: none;
        position: relative;
        top: -10px;
        height: 20px;
        width: $fixed-width;

        &-right {
            border-right: 1px solid $color-gray-light;
            border-bottom: none;
            width: $fixed-width-small;
        }

        &-bottom {
            border-bottom: none;
        }
    }
}

.edit-view {
    .day {
        border: 1px solid $color-gray-light;
        min-height: 150px;
        overflow: hidden;
        margin-top: -1px;
        margin-left: -1px;

        @include media-breakpoint-down(lg) {
            font-size: 12px;
        }

        .slots {
            @include media-breakpoint-down(sm) {
                min-width: 50px;
                word-break: break-all;
            }
        }

        .icon {
            font-size: 24px;

            @include media-breakpoint-down(sm) {
                font-size: 11px;
            }
        }
    }

    .available-slot {
        border-radius: 0;
        min-width: 90px;
        margin-top: -1px;
        margin-left: -1px;
    }
}

.coach-profile-image {
    width: 42px;
    height: 42px;

    @include media-breakpoint-down(md) {
        width: 32px;
        height: 32px;
    }
}

.calendar-view-option:hover,
.pagination-button:hover {
    color: $color-white !important;
}

.border {
    border-top: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    border-left: 1px solid #ddd !important;

    &-no-top {
        border-top: none;
        border-right: 1px solid #ddd !important;
        border-bottom: 1px solid #ddd !important;
        border-left: 1px solid #ddd !important;
    }

    &-bottom-left {
        border-top: none;
        border-right: none;
        border-bottom: 1px solid #ddd !important;
        border-left: 1px solid #ddd !important;
    }

    &-no-right {
        border-top: 1px solid #ddd !important;
        border-right: none;
        border-bottom: 1px solid #ddd !important;
        border-left: 1px solid #ddd !important;
    }
}

.loader {
    height: 400px;
}
