img.logo {
    width: 150px;
    margin: 10px;
}

.ts-branded-logo {
    height: 100%;

    &-signup {
        height: auto;
        max-width: 10rem;

        @media (min-width: 768px) {
            height: auto;
            max-width: 20rem;
        }
    }
}

.ts-small-logo {
    height: 45px;
}

.ts-small-logo-collapse {
    height: 40px;
}
