.ts-header {
    height: $layout-2-header-height;
    min-height: $layout-2-header-height;
    z-index: z-index(menu-overlay);
    background-color: $color-white;
    position: fixed;
    top: 0;
    right: 9;

    .ts-nav-link,
    .dropdown-toggle {
        color: $color-main-dark;
        margin: 1.5rem;
        border-bottom: 2px solid transparent;

        &:hover {
            text-decoration: none;
        }

        &.selected {
            border-color: $color-main-dark;
        }
    }

    .mobile-menu-overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: z-index(modal-overlay);
        background-color: $color-white;
        color: $color-main-dark;
        display: none;

        &.show {
            display: flex;
            flex-direction: column;
        }

        &__header {
            height: $layout-2-header-height;

            .close-button {
                position: absolute;
                right: 1rem;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $color-main-dark;
            }
        }
    }

    .logo img {
        height: 40px;
    }

    .ts-coach-chat {
        width: 3rem;
        height: 3rem;
        color: $color-gray;
        border-radius: 25px;

        &.expanded {
            color: $color-main-alt;
        }

        &:hover {
            color: $color-main-alt !important;
            background-color: rgba($color-main-alt, 0.1) !important;

            .ts-chat-icon {
                color: $color-main-alt !important;
            }
        }
    }
}
