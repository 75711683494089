.ts-client-search-popover {
    position: absolute;
    top: 110px;
    z-index: 1;
}

#compose-mail {
    display: none;
}

.ts-composer {
    width: 100%;
    border: 1px solid rgba(#919eab, 0.32);
    border-radius: 10px 0;

    @media (min-width: 768px) {
        width: 60%;
    }
}

.ql-clipboard {
    display: none;
}

.ql-tooltip {
    display: none;
}
