.ts-program-resources-viewer {
    height: 40rem;

    .select-resource-list {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $color-gray;

        &.selected {
            border: 1px solid $color-green;
        }
    }
}
