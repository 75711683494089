.toast-container {
    .Toastify__toast {
        border-radius: 5px;

        &-body {
            margin: 1rem 3rem 1rem 1rem;
        }

        .toast-container__close-button {
            position: absolute;
            right: 15px;
            background-color: none;
            font-size: 1.3rem;
            border-radius: 0;
            color: white;

            &:active {
                background-color: none;
            }
        }

        &--success {
            background-color: $color-green;
        }

        &--error {
            background-color: $color-red;
        }
    }
}
