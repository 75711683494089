.ts-provider-search-header {
    background-image: url("./res/search-bg.png") url("./res/therapist_search.webp");
}

.ts-therapist-search-image {
    position: relative;
    width: 250px;
    height: 300px;
    top: -50px;
    right: 50px;
}

#ts-google-maps div {
    &:first-child {
        background: none !important;
    }
}

.ts-provider-results-list {
    max-width: 100%;
    height: 100%;
    z-index: 99;
    overflow: scroll;

    @media (min-width: 1200px) {
        max-width: 600px;
        min-width: 600px;
        height: 90%;
        overflow: scroll;
    }
}

.ts-provider-results-map {
    max-width: 100%;
    height: 0%;
    overflow: scroll;

    @media (min-width: 1200px) {
        max-width: 600px;
        min-width: 600px;
        height: 90%;
        overflow: scroll;
    }
}

.ts-clinic-carousel {
    .slick-list {
        max-width: 400px;
    }
}

.pac-container {
    z-index: 9999;
}
