.ts-orbit-rebrand-notification {
    background-image: url("./res/coach_consult.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__content {
        text-shadow: 2px 2px 2px rgba($color-black, 0.5);

        .home-button {
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }
}

.ts-orbit-rebrand-notification:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($color-orbit-blue, 0.95);
}
