.ts-chat-toggle {
    .ts-unread-message {
        height: 0.55rem;
        width: 0.55rem;
        border-radius: 10px;
        bottom: 20px;
        left: 16px;

        &.show {
            .notification-icon {
                width: 0.5rem;
                height: 0.45rem;
                background-color: $color-red;
                border: 1px solid $color-white;
                border-radius: 20px;
            }
        }
    }

    .ts-chat-icon {
        margin-top: 0.25rem;
        color: $color-gray;

        &.expanded {
            color: $color-main-alt;
        }
    }
}
