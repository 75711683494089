.ts-resources-list-editor {
    // min-height: 25rem;

    .resource-card-ghost {
        background-color: $color-gray-light-alt;
    }

    .card-drop-preview {
        background-color: rgba(150, 150, 200, 0.1);
        border: 1px dashed #abc;
        border-radius: 20px;
        width: 200px;
        height: 200px;
        margin: 5px;
    }

    .ts-dnd-container {
        display: flex !important;

        .ts-empty-program-session {
            min-width: 200px !important;
            max-width: 200px !important;
            height: 200px !important;
            margin: 4px;
            border-radius: 20px;
            background-color: $color-gray-light-alt;
            padding: 20px;
        }
    }

    .resource-container {
        height: 8rem;
        width: 8rem;
    }

    .ts-edit-list {
        // height: 30rem !important;

        &-search-container {
            height: 30rem !important;

            &-search-results {
                max-height: 25rem;
            }
        }

        .add-item {
            min-height: 2rem;

            &-title:hover {
                text-decoration: underline;
            }

            &-button {
                min-width: 2rem;
                height: 2rem;
                transition: none;
            }

            &-button:disabled {
                background: none !important;

                i {
                    color: $color-gray-alt !important;
                }
            }

            &-button:hover {
                width: 2rem;
                height: 2rem;
                background-color: $color-green !important;
                border: 2px solid $color-green !important;

                i {
                    color: #fff !important;
                }
            }

            .video-thumbnail {
                max-width: 64px;
            }
        }

        .list-group {
            height: 32rem;

            .list-item-ghost {
                background-color: $color-white;
            }

            .drop-preview {
                background-color: rgba(150, 150, 200, 0.1);
                border: 1px dashed #abc;
                margin: 5px;
            }

            .item-title {
                max-width: 30rem !important;
            }

            .item-title:hover {
                text-decoration: underline;
            }
        }
    }

    .list-name-input {
        border-radius: 30px;

        label {
            top: -0.75rem;
            left: -0.8rem;
            padding-left: 1.25rem;
        }

        #list-name {
            border: none;
            padding: 0.5rem;
            margin-left: 0.5rem;
        }
    }
}

.routine-drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
    border-radius: 20px;
    height: 280px;
    // width: 100%;
    margin: 20px;
}
