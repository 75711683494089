.ts-horizontal-image {
    float: left;
    max-width: 160px !important;
    height: 120px !important;

    @media (max-width: 1200px) {
        width: 100%;
    }

    img {
        max-width: 160px !important;
        height: 120px !important;

        @media (max-width: 1200px) {
            text-align: center;
        }
    }
}

.ts-action-card-body {
    display: -webkit-box !important;
    overflow: hidden !important;
    -webkit-line-clamp: 5 !important;
    -webkit-box-orient: vertical !important;
}
