.ts-header-subtitle {
    font-family: "Montserrat", "Roboto", Arial;

    &.ts-contained {
        padding: 0 1rem;

        @media (min-width: 768px) {
            width: 50% !important;
            padding: 0;
        }
    }
}
