.ts-dropzone {
    .dropzone-input {
        max-width: 30rem;
        min-height: 10rem;
        border: 2px dashed $color-gray;
    }

    .ts-dropzone-preview {
        max-width: 20rem;
        max-height: 10rem;
    }
}
