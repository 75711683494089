.ts-import-popover {
    position: relative;
    max-width: 800px;
    min-width: 400px;
    z-index: 100;
    top: 0;
    left: 247px;

    .ts-resource-search-helper {
        border-top: 1px solid $color-gray;
    }

    .ts-search-history-item {
        &:hover {
            background-color: $color-gray-light-alt;
        }
    }
}
