.ts-program-creator {
    .ts-program-sessions {
        height: 38rem;

        .container-ghost {
            background-color: $color-gray-light-alt;
            width: 100%;
            padding-left: 2rem;
        }

        .container-ghost-drop {
            transition: transform 0.18s ease-in-out;
            transform: rotateZ(0deg);
        }

        .drop-preview {
            background-color: rgba(150, 150, 200, 0.1);
            border: 1px dashed #abc;
            margin: 5px;
        }
    }

    .program-select-title {
        min-width: 10rem;
    }

    .MuiAutocomplete-root {
        width: 375px;

        @media (max-width: 1200px) {
            width: 100% !important;
        }
    }

    #description-label,
    #program-title-label,
    #pain_location {
        color: black !important;
    }

    .ts-pain-location-field {
        color: black !important;
    }

    .program-title-field,
    .description-field {
        border-radius: 30px;
        min-width: 28rem;
        background-color: $color-white;

        label {
            top: -0.6rem;
            left: -0.8rem;
            padding-left: 1rem;
        }

        input {
            padding: 0.5rem 1rem !important;
        }
    }

    .ts-program-label {
        border-radius: 6rem;
    }
}
