.ts-resources-list {
    .contained-results {
        max-height: 45rem;
        overflow-y: auto;
    }

    .list-button {
        background: none;
    }

    .pagination {
        width: 48px;
        height: 48px;
        border-radius: 20px;
    }

    .resources-search {
        border-radius: 30px;

        .resources-search-icon {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }

    #resources-search {
        border: none;
        padding: 0;
        margin-left: 1rem;
    }

    .resources-search::before {
        border-bottom: none;
    }
}
