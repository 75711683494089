.ts-search-bar {
    max-width: 23rem;

    &.outlined {
        border-radius: 30px;
        border: 1px solid $color-gray-light !important;
    }

    .ts-search-field {
        height: 2rem;
        border-radius: 30px;
    }

    #ts-search-field {
        padding: 0;
        margin-left: 1rem;
    }
}
