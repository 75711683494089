.ts-single-clinic-image {
    width: 100%;
    height: 100%;
}

.ts-clinic-image-carouel {
    .slick-list {
        height: 100%;
    }

    .slick-slider {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide {
        height: 100%;

        div {
            height: 100%;

            img {
                min-height: 300px;
            }
        }
    }

    .slick-dots div,
    img {
        height: 100%;
    }
}

.ts-image-edit-button {
    position: relative;
    top: -20;
}
